
		  @import "src/styles-theme/variables.scss";
		  


.sign-up-email-container {
    height: 50vh;
    .inputs {
             
        margin: $size-16 0 $size-48 0;
    
       
    }

    .check-email-button {
        display: flex;
        justify-content: center;
        margin: $size-32 0;
        button {
            width: 234px;
        }
    }
}