
		  @import "src/styles-theme/variables.scss";
		  
.explainer-info-icon {
	margin-left: 5px;
	width: 10px;
	height: 10px;
}

.check-status-type:hover {
	opacity: 0.5
}

.external-user-profile-status-type {
	margin: 0;
	max-height: 25px;
	max-width: 150px;
	display: flex;
	align-items: center;
	border-radius: 15px;
	overflow: hidden;
	font-size: 14px;
	padding: 2px 10px;
	font-weight: 500;
	margin-right: 5px;

	.rtw {
		color: $status-rtw;
	}

	.bs7858 {
		color: $status-bs7858;
	}

	.rtwbs7858 {
		color: $status-rtwbs7858;
	}

	.status-awaiting {
		color: $status-awaiting;
	}

	.status-in-progress {
		color: $status-in-progress;
	}

	.status-limited {
		color: $status-limited;
	}

	.status-full {
		color: $status-full;
	}

	.status-cancelled {
		color: $status-cancelled;
	}
}