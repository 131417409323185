
		  @import "src/styles-theme/variables.scss";
		  


.check-container {

    @media only screen and (max-width: 720px) {
        margin-top: $size-96!important;
    }
   
}