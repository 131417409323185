
		  @import "src/styles-theme/variables.scss";
		  


.sort-by-section {
  margin-right: $size-24;
  height: calc(100% - 8px);
    .sort-by-text {
      margin-bottom: 8px;
      p {
        font-size: 12px;
        color: $color-grey-3;
        transform: translateY(6px);
        font-weight: $font_semi_bold;
        margin-left: 2px;
      }
    }
  }

