
		  @import "src/styles-theme/variables.scss";
		  


.logo {
    margin-bottom: $size-40;
    img {
        width: $size-240;
    }
}