
		  @import "src/styles-theme/variables.scss";
		  


.filter-page-container {
    position: absolute;
    z-index: 10000000000;
    width: calc(100vw - 64px);
    height: 100vh;
    background-color: $color-white;
    top: 0;
    left: 0;
    padding: $size-32;
    overflow-y: scroll;
    .heading {
        img {
            cursor: pointer;
        }
        h2 {
            font-size: $size-16;
            font-weight: $font-demi_bold;
            color: $color-black;
            margin-top: $size-16;
            line-height: $size-64;

        }
		.filter-heading-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
    }
    .filter-item {
        width: inherit;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-8;
        height: $size-64;
        cursor: pointer;
        p {
            font-weight: $font-semi_bold;
            font-size: 14px;
            color: $color-black;
        }
    }
	.clear-btn {
		display: flex;
		button {
			align-self: flex-end;
			height: 34px;
			background-color: $color-white;
			outline: none;
			border: 1px solid $primary-color;
			border-radius: 6px;
			margin-bottom: 1px;
			color: $primary-color;
			padding: 0 $size-8;
			cursor: pointer;
		}
	}
}
