
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-row-outer {
    position: relative;
    min-width: max-content;
    &.remove-position {
        position: unset;
    }
    .dashboard-row {
        display: flex;
    }

    .dashboard-row-dark {
        display: flex;
        position: absolute;
        background-color: $color-grey-8;
        width: 100%;
        top: 0;
        opacity: 0.6;
        height: $size-80;
        pointer-events: none;
        z-index: 995;
    }
}


