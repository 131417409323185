
		  @import "src/styles-theme/variables.scss";
		  


.add-staff-info-container {
    display: flex;
    align-items: center;
    .add-staff-avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $color-grey-8;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $size-32;
        .add-staff-avatar-letter {
            font-size: 12px;
            font-weight: $font_demi_bold;
        }

    }
    .color-green {
        color: #1DC7B2;
    }
    .color-blue {
        color: #485BEB;
    }
    .color-orange {
        color: #FF7B00;
    }
    .color-purple {
        color: #9013FE;
    }
    .color-red {
        color: #EC8079;
    }

    .add-staff-name {
        font-size: 14px;
        font-weight: $font-demi_bold;
        color: $color-grey-1;

    }
    .add-staff-role {
        font-size: 14px;
        font-weight: $font-semi_bold;
        color: $color-grey-1;
    }

}
