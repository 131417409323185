
		  @import "src/styles-theme/variables.scss";
		  


.default-flow-container {
    background-color: $color-white;
    padding: $size-32;
    margin-bottom: 4px;
	p {
		font-size: 14px;
		font-weight: $font_semi_bold;
		color: $color-grey-3;
		padding-bottom: 4px;
	}
}