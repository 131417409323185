
		  @import "src/styles-theme/variables.scss";
		  


.activity-contact-section-outer {
    .contact {
        display: flex;
        align-items: center;
        background-color: $color-red;
        //margin-top: $size-24;
        padding: 6px;
        p {
            font-size: 12px;
            font-weight: $font_demi_bold;
        }
        img {
            margin-right: $size-8;
        }
    }
}
.activity-contact-section {
    padding: $size-24 0;
    display: flex;
    flex-wrap: wrap;

    @media print {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
        background-color: white;

    }

    .print-heading {

        h1 {
            opacity: 0;
            margin-top: -$size-32;
        }
        @media print {

            h1 {
                font-size: $size-40;
                opacity: 1;
                margin-top: 0;
            }
            display: block;
            margin-bottom: $size-40;
        }
    }

}
