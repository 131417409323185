
		  @import "src/styles-theme/variables.scss";
		  
.reset-password-input-container {
    height: 50vh;
    .inputs {
        margin: $size-16 0 $size-48 0;
    }

    .validation-info {
        width: 373px;
        margin-bottom: $size-24;
        margin-top: -$size-16;

        p {
            color: $color-grey-2;
            font-size: 10px;
            line-height: 22px;
        }

        @media only screen and (max-width: 600px) {
            width: 300px;
        }
        @media only screen and (max-width: 340px) {
            width: 280px;
        }
    }
    .password-validation-error {
        p {
            color: $color-red-font;
        }
    }

    .reset-password-input-button {
        display: flex;
        justify-content: center;
        margin: $size-32 0;
        button {
            width: 234px;
        }
    }
}
