
		  @import "src/styles-theme/variables.scss";
		  
.liveness-check-container {
	margin-left: $size-72-5;
	background-color: $color-grey-8;
	padding: $size-32;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 700px) {
		margin-left: 0;
	}
	.left-button,
	.right-button {
		cursor: pointer;
	}
}
.external-user-profile-sub-section-liveness {
	background-color: $color-grey-8;
	padding: $size-32;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 700px) {
		margin-left: 0;
	}
	.left-button,
	.right-button {
		cursor: pointer;
	}
}
.each-slide-effect img {
	height: 300px;
	border-radius: $size-14;
}
.disable {
	opacity: 0.4;
}
.visible {
	opacity: 1;
}
