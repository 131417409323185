
		  @import "src/styles-theme/variables.scss";
		  
.full-page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9997;
}