
		  @import "src/styles-theme/variables.scss";
		  
.orka-button {
    button {
        background-color: $primary-color;
        color: white;
        border-radius: 4px;
        border: none;
        outline: none;
        line-height: 23px;
        font-size: $button_font_size;
        font-weight: $button_font_weight;
        color: $color_white;
        display: inline-block;
        cursor: pointer;
        min-width: $button_width;
        height: $button_height;

    }
    button:disabled {
        background-color: lighten($primary-color, 20%);
    }
}