
		  @import "src/styles-theme/variables.scss";
		  


.stage-dropdown-container {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-grey-8;
    width: 100%;
    height: $size-48;
    padding: 0 $size-24 0 $size-32;
    margin-left: $size-8;
    border-radius: 5px;
    cursor: pointer;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
        padding-left: $size-16;
    }
    .dropdown-icons {
        display: flex;

        img {
            height: 12px;
            width: 12px;
            margin-right: 2px;
            &:last-of-type {
                margin-left: 8px;
            }
            
        }
        
    }
    
    p {
        color: $primary-color;
        font-weight: $font_semi_bold;
        @media only screen and (max-width: 700px) {
            font-size: 14px;
            font-weight: normal;
        }
        @media only screen and (max-width: 400px) {
            font-size: 12px;
            font-weight: normal;
        }
    }
    &.stage-complete {
        background-color: $primary-color;
        p {
            color: $color-white;
        }
    }
}