
		  @import "src/styles-theme/variables.scss";
		  


.ambassador-search-component {
    background-color: $color-grey-8;

    display: flex;
  
    align-items: center;
    border-radius: 4px;
    width: $size-240;
    height: $size-40;
    @media only screen and (max-width: 720px) {
        width: 300px;
        margin-left: $size-16;
    }
    .search-btn {
        background-color: $primary-color;
        height: 100%;
        width: $size-48;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        
    }
 
  
    input {
        height: 100%;
        width: 100%;
        font-size: 14px;
        height: $size-32;
        font-weight: $font_semi_bold;
        background-color: $color-grey-8;
        color: $color-grey-3;
        padding: 0 12px;
        border: none;
        outline: none;
        &::placeholder {
            color: $color-grey-3;
 
        }


    }
}
