
		  @import "src/styles-theme/variables.scss";
		  
.external-settings {
	scroll-padding-top: 3.5rem;
	overflow-y: scroll;
	.external-settings-container {
		border: 1px solid $primary-color;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		.external-settings-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 8px;
			border-bottom: 1px solid $primary-color;
			.external-settings-header-text {
				display: flex;
				align-items: center;
				margin-left: 20px;
				gap: 8px;
				font-weight: bold;
				.external-settings-role-icon {
					width: 20px;
					height: 20px;
				}
				.external-settings-title {
					text-transform: uppercase;
					font-weight: 600;
					margin-bottom: 3px;
				}
			}
		}
		.external-settings-table {
			width: 100%;
			border-collapse: collapse;
			.external-settings-th-left {
				text-align: left;
			}
			.external-settings-th-right {
				text-align: right;
			}
			.external-settings-th-left,
			.external-settings-th-right {
				padding: 10px 20px;
			}
			.external-settings-tr {
				border-top: 1px solid $color-grey-4;
				margin-left: 5px;
				.external-settings-td {
					display: flex;
					align-items: center;
					gap: 8px;
					padding: 12px;
					.external-settings-default-text {
						color: $color-grey-3;
						font-weight: 500;
						margin-left: 10px;
					}
					.external-settings-initials {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: small;
						font-weight: 600;
						width: 35px;
						height: 35px;
						border-radius: 50%;
						color: #80C08D;
						background-color: $color-grey-5;
						margin-right: 5px;
					}
					.external-settings-name {
						text-transform: capitalize;
						font-weight: 600;
					}
					.external-settings-email {
						font-size: small;
						color: $color-grey-3;
					}
				}
				.external-settings-td-right {
					padding: 12px;
					text-align: right;
					.external-settings-edit-icon,
					.external-settings-delete-icon {
						background: none;
						border: none;
						cursor: pointer;
					}
					.external-settings-edit-icon {
						margin-right: 8px;
					}
				}
			}
		}
	}
}