
		  @import "src/styles-theme/variables.scss";
		  
iframe {
	width: 100%;
	height: 100vh;
	z-index: 9998;
	position: absolute;
	top: 0;
  	left: 0;
  	bottom: 0;
 	right: 0;
}

.test {
	border: 1px solid red;
}