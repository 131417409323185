
		  @import "src/styles-theme/variables.scss";
		  
.review-modal-container {
	width: 300px;
	box-shadow: -5px 5px 10px $color-grey-4;
	margin-left: 5px;
	.review-modal-title {
		padding-top: 15px;
		.review-modal-header {
			font-weight: $font-semi-bold;
			margin-top: 10px;
			margin-bottom: 5px;
			margin-left: 10px;
		}
		.review-modal-divider {
			width: 100%;
			border-bottom: 1px solid $color-grey-4;
			margin-bottom: 20px;
		}
		.review-modal-close {
			width: 10px;
			height: auto;
			float: right;
		}
	}
	.review-modal {
		display: flex; 
		flex-direction: column;
		justify-content: space-between;
		padding: 0 5px;
		height: 85vh;
		.review-modal-top {
			padding: 0 15px;
			.review-modal-confirmation-container {
				padding: 15px;
				border: 1.5px solid $color-grey-4;
				border-radius: 6px;
				.review-modal-expiry {
					text-align: center;
				}
				.review-modal-confirmation {
					display: flex;
					flex-direction: row;
					.review-modal-confirmation-checkbox {
						padding-right: 5px;
						padding-top: 5px;
					}
				}
				.review-modal-confirmation-message {
					display: flex;
					flex-direction: column;
					padding: 5px 5px 5px;
					line-height: 1.5;
				}
				.confirmation-button {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 34px;
					background-color: $color-primary;
					border: none;
					border-radius: 6px;
					margin: 10px 5px 0 5px;
					color: $color-white;
					cursor: pointer;
					.confirmation-button-text {
						border: none;
						color: $color-white;
						background-color: $color-primary;
					}
				}
				.review-modal-confirmation-button:disabled {
					background-color: lighten($color-primary, 10%);
				}
			}
			.validation-error-text {
				font-size: 12px;
				color: $color-red-font;
				line-height: 1.5;
				padding: 10px;
			}
			.review-modal-verified {
				padding: 1em;
				border: 1px solid $color-grey-4;
				p {
					padding: 3px 0;
				}
				.review-modal-verified-title {
					padding: 5px;
					margin: 0px 10px;
					color: #009951;
					background-color: #CFF7D3;
					border-radius: 10px;
					text-align: center;
				}
			}
			.review-modal-pending {
				padding: 1em;
				border: 1px solid $color-grey-4;
				.review-modal-pending-title {
					padding: 5px;
					margin: 0px 10px;
					color: $color-grey-1;
					background-color: $color-grey-4;
					border-radius: 10px;
					text-align: center;
				}
			}
			.review-modal-guidance {
				display: flex;
				align-items: center;
				justify-content: center;  
				margin-top: 20px;
				background-color: $color-yellow;
				border: 1px solid $color-yellow-dark;
				border-radius: 6px;
				padding: 15px;
				cursor: help;
				.review-modal-guidance-img {
					margin-right: 5px;
					padding: 10px;
				}
			}
		}
		.review-modal-bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			
			.review-action-button {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 34px;
				width: 180px;
				background-color: $color-red-dark;
				border: none;
				border-radius: 6px;
				margin-bottom: 1px;
				color: $color-white;
				padding: 0 $size-8;
				cursor: pointer;
				margin-bottom: 10px;
			  }
	
			  .review-action-text {
				line-height: 1;
			  }
		}
	}
}