
		  @import "src/styles-theme/variables.scss";
		  
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    label {
        font-weight: 500;
    }

    .checkbox-control {
        position: relative;
        border: 1px solid #d7d7d7;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
        width: 20px;
        height: 20px;

        input {
            appearance: none;
            margin: 0;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            cursor: pointer;

            &:checked {
                background-color: #485BEB;
                border-color: #485BEB;
            }

            &:checked:after {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 6px;
                width: 6px;
                height: 10px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
				border-radius: 2px;
                transform: rotate(45deg);
            }
        }
    }
}