
		  @import "src/styles-theme/variables.scss";
		  
.external-tabs-container {
	margin-top: 1.5625rem;
	background-color: $color-grey-5;
	.tab-number {
		pointer-events: none;
	}
	
	.tabs {
		display: flex;
		justify-content: space-between;
	}
	
	.tabs button {
		transition: all 0.3s ease;
	}

	.tabs button {
		font-family: inherit;
		font-size: 100%;
		font-weight: 500;
		border: none;
		color: $color-grey-1;
		cursor: pointer;
		padding: 20px;
		width: 16.6667%;
		background-color: $color-white;
		text-align: left;
		margin-bottom: 2px;
	}

	.tabs button:not(:last-child) {
		margin-right: 2px;
	}

	.orange-bullet::after {
		content: "\2022"; /* the Unicode character for a bullet point */
		color: #FC8E28;
	}

	.orange-bullet {
		pointer-events: none;
		text-align: left;
		font-size: inherit;
		font-weight: inherit;
		font-family: inherit;
		font-style: inherit;
		color: inherit;
	}
	
	@media only screen and (max-width: 960px) {
		.container {
			width: 100%;
		}
	
		.content {
			padding: 50px;
		}
	}
}
