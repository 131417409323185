
		  @import "src/styles-theme/variables.scss";
		  
.certificate-list-item-container {
    margin-bottom: $size-24;
    .cert-section-title {
        background-color:$color-grey-8;
        margin-bottom: $size-8;
        padding-left: $size-16;
        p {
            font-size: 10px;
            color: $color-grey-3;
            font-weight: $font_semi_bold;

        }
    }
    .certificate-list-item {
        border-bottom: 1px solid $color-grey-8;
        margin-left: $size-24;
        .certificate-list-item-top {
            display: flex;
            align-items: center;
            min-height: $size-40;
            p {
                font-size: 10px;
                color: $color-black;
                font-weight: $font_semi_bold;
                &.grey-text {
                    color: $color-grey-3;
                }
                &.stage-complete {
                    font-weight: $font_semi_bold;
                }
            }
            .col-stage-fill {
                flex: 2.5;
            }
            .col-stage-md {
                flex: 1;
                text-align: center;
            }
            .col-stage-sm {
                flex: 0.3;
                img {
                    margin-top: 4px;
                }
            }
        }
        .risk-row-section {
            .risk-row {
                display: flex;
                align-items: flex-start;
                padding: 4px 0;
                p {
                    font-size: 10px;
                }
                .risk-title {
                    font-weight: $font_semi_bold;
                    margin-right: $size-8;
                    min-width: 80px;
                    
                }
                .risk-notes {
                    padding-right: $size-16;
                } 
                img {
                    height: 10px;
                    margin-right: $size-8;
                    margin-top: $size-8;
                }
            }
        }       
    }
}