
		  @import "src/styles-theme/variables.scss";
		  
.activity-documents-section {
	.draggable-area {
		border: 1px solid $color-grey-5;
		padding: $size-8;
		border-radius: 5px;
	}
	.draggable-area-active {
		border: 1px solid $primary-color;
		padding: $size-8;
		border-radius: 5px;
	}
	.profile-doc-container {
		.file-list-container {
			.file-item {
				.file-item-left {
					.file-link {
						background-color: $color-white;
					}
				}
				.file-item-right {
					background-color: $color-white;
				}
			}
		}
	}
}
