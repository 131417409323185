$primary-color: #7B7DFC;
$color-grey-1: #4a4a4a;
$color-grey-2: #9b9b9b;
$color-grey-3: #9aa5b1;
$color-grey-4: #d0d8e0;
$color-grey-5: #e6e6e6;
$color-grey-6: #f0f0f0;
$color-grey-7: #fbfcfd;
$color-grey-8: #f7f7f7;

$color-primary: $primary-color;
$color-primary-lighter: #B9B9F9;
$color_yellow: #FFEBB8;
$color-yellow-dark: #F8C96A;
$color-yellow-font: #E6A501;
$color-green: #c4efea;
$color-green-font: #1dc7b2;
$color-purple: #9013FE;
$color-red: #FFF0F1;
$color-red-dark: #EC8079;
$color-red-font: #fd6d6d;

$color-white: white;
$color-black: #000;

$spacer-1: 8px;
$spacer-2: 16px;
$spacer-3: 24px;
$spacer-4: 32px;

$font_family: "AvenirNext";
$font_bold: 700;
$font_ultra_bold: 800;
$font_demi_bold: 600;
$font_semi_bold: 500;
$font_normal: 400;
$font_light: 300;

$shadow_color: rgba(0, 0, 0, 0.2);
$shadow_color_light: rgba(228, 228, 228, 0.5);
$form_input_font_size: 16px;

$shadow_1: 0px 1px 3px 0px $shadow_color;
$shadow_2: 0px 4px 6px 0px $shadow_color;
$shadow_3: 0px 5px 15px 0px $shadow_color;
$shadow_4: 0px 10px 24px 0px $shadow_color;
$shadow_5: 0px 15px 35px 0px $shadow_color;
$shadow_6: 0 1px 21px 0 $shadow_color_light;

$button_shadow: none;
$button_font_size: 14px;
$button_font_weight: $font_semi_bold;
$button_height: 46px;
$button_width: 137px;
$button_radius: 6px;
$button_padding: 16px;
$button_long_width: 269px;

$size-4: 4px;
$size-8: 8px;
$size-14: 14px;
$size-16: 16px;
$size-24: 24px;
$size-32: 32px;
$size-40: 40px;
$size-48: 48px;
$size-56: 56px;
$size-64: 64px;
$size-72: 72px;
$size-72-5: 72.3px;
$size-80: 80px;
$size-88: 88px;
$size-96: 96px;
$size-104: 104px;
$size-112: 112px;
$size-120: 120px;
$size-160: 160px;
$size-192: 192px;
$size-200: 200px;
$size-240: 240px;
$size-256: 256px;
$size-304: 304px;
$size-400: 400px;
$size-560: 560px;
$size-720: 720px;

$dashboard-label-size: $size-14;

// Status Colors

$status-awaiting: #9AA5B1;
$status-in-progress: #485BEB;
$status-limited: #E6A501;
$status-full: #09AD99;
$status-cancelled: #EC8079;
$status-rtw: #A15CA7;
$status-bs7858: #6264FF;
$status-rtwbs7858: #589A62;