
		  @import "src/styles-theme/variables.scss";
		  


.mobile-header-buckets {
    margin: $size-32 0;
    background-color: $color-primary;
    position: fixed;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    z-index: 100000;
    padding: $size-16 0;
    &::-webkit-scrollbar {
        width: 0px;
    }
    .mobile-header-bucket-items {
        border-bottom: 1px solid $color-grey-6;
    }

    .mobile-search {
        margin: $size-16 0;
    }
	.search-container {
		margin-top: $size-24;
		margin-bottom: $size-24;
	}
    .bucket-item {
        display: flex;
        align-items: center;
        margin-bottom: $size-16;
        justify-content: space-between;
        padding: 4px $size-16;
       
        cursor: pointer;
        .bucket-item-info {
            display: flex;
            align-items: center;

            img {
                width: $size-16;
                height: auto;
                margin-right: $size-24;
            }
            .bucket-selected {
                color: $primary-color;
            }
            p {
                font-weight: 400;
                font-size: $size-14;
                color: $color-grey-1;

            }               
            
        }

        .bucket-amount {
            background-color: $color-grey-8;
            border-radius: 100px;
            padding: 0 10px;
            height: $size-24;
            min-width: $size-40;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                font-size: 12px;
                font-weight: $font_demi_bold;
                
            }

        }
    }

    .sidebar-action-lg {
		padding: $size-16;
        margin-bottom: $size-16;
        display: flex;
        cursor: pointer;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        img {
            width: 20px;
            height: auto;
            margin-right: 20px;
            
        }
        .logout-txt {
            color: $color-red-font;
            font-weight: 400;
            font-size: $size-14;
            
        }
        .link-text {
            font-weight: 400;
            font-size: $size-14;
            color: $color-white;
        }
    }
	.active-tab {
		background-color: $color-primary-lighter;
		margin: 10px;
	}
    .spacer {
        height: $size-96;
    }

    
}



@keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}