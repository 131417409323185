
		  @import "src/styles-theme/variables.scss";
		  


.login-inputs-section {
    height: 50vh;
   
    .inputs {
        margin-bottom: $size-24;
        margin: $size-16;
    }
    .login-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: $size-16;
        .login-button {
            width: 234px;
        }
    }

    .forgot-password {
        margin-top: $size-24;
        display: block;
        text-align: center;
        a {
            color: $primary-color;
            cursor: pointer;
        }
    }
}

