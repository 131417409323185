
		  @import "src/styles-theme/variables.scss";
		  
.guidance-toggle-container {
	border: solid 2px $color-primary;
	border-radius: 10px;
	margin-top: 10px;
	padding: 10px;

	.guidance-toggle-title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.demi-bold {
		font-weight: $font_demi_bold;
	}

	.guidance-toggle-text {
		color: $color-primary;
	}
}