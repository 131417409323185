
		  @import "src/styles-theme/variables.scss";
		  


.activity-date-section {
    padding: $size-24 0;
    
    @media only screen and (max-width: 700px) {
        
        padding: $size-24 $size-24;
    }
    .activity-date-section-top {
        display: flex;
        flex-wrap: wrap;
    
        margin-bottom: $size-16;
       
    }
    .first-item {
        margin-right: $size-64;
      
    }

    .reason-for-leaving {
        padding-bottom: $size-16;
        .other-reason {
            textarea {
                width: 50%;
                height: $size-120;
                margin-top: $size-32;
                background-color: $color-white;
                border: 1px solid darken($color-grey-6, 5%);
                outline: none;
                border-radius: 4px;
                font-size: 14px;
                padding: 8px;
                color: $color-grey-1;
                font-size: 14px;
            }
        }
    }
    .error-validation {
        p {
            font-size: 12px;
            color: $color-red-font;
            font-weight: $font_semi_bold;
            
        }
    }
   
   
}