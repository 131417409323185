
		  @import "src/styles-theme/variables.scss";
		  


.risk-item-container {
    display: flex;
    align-items: flex-start;
   

    background-color: $color_grey_8;
    padding:$size-24;  
    margin-bottom: 4px;
    
    .risk-item-icon {
        min-width: $size-32;
      
    }
    .risk-item-info {
        flex: auto;
        .risk-item-info-top {
            display: flex;
            align-items: center;
            h4 {
                font-weight: $font_semi_bold;
                font-size: 14px;
                margin-right: $size-8;
            }
            p {
                font-weight: $font-semi_bold;
                font-size: 12px;
            }
        }

        p {
            font-size: 12px;
        }
       
    }
    .risk-item-actions {
        min-width: $size-48;
        display: flex;
       
        justify-content: space-between;
        img {
            cursor: pointer;
        }


    }
  
}




