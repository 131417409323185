
		  @import "src/styles-theme/variables.scss";
		  


.user-profile-page {
    flex-grow: 1;
    height: 95vh;
	position: relative;


    @media only screen and (max-width: 720px) {
        height: calc(95vh - 80px);

    }

    background-color: $color-grey-8;
    // height: 100%;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;

    .page-spinner {

        height: inherit;
        width: inherit;

    }
}

@keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}