
		  @import "src/styles-theme/variables.scss";
		  
.company-filter-container {
	height: calc(100% - 8px);

	.company-filter {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: inherit;
		width: 100%;

		.company-filter-text {
			margin-bottom: 8px;

			p {
				font-size: 12px;
				color: $color-grey-3;
				transform: translateY(6px);
				font-weight: $font_semi_bold;
				margin-left: 2px;
			}
		}
	}
}
