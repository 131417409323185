
		  @import "src/styles-theme/variables.scss";
		  

.activity-main-container {
    padding: $size-16 $size-32 $size-32 $size-32;
    display: flex;
    flex-direction: column;

    .work-pattern {
        margin: $size-8 0;
        
    }
    
    .main-container-info {
        display: flex;
        margin-top: $size-24;
        flex-wrap: wrap;
        
    }
    textarea {
        border: 1px solid #D0D8E0;
        border-radius: 5px;
        width: calc(100% - 32px);
        height: $size-160;
        margin-top: $size-32;
        outline: none;
        padding: 12px;
    }
   
}
