
		  @import "src/styles-theme/variables.scss";
		  


.notes-section-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.notes-section-button-container {
		display: flex;
		justify-content: flex-end;
		margin-right: -10px;
    	margin-top: 36px;
	}
	
	textarea {
		border: 1px solid $color-grey-4;
		border-radius: 5px;
		width: 100%;
		height: 100%;
		outline: none;
		padding: 12px;
		margin-left: -16px;
		margin-right: -18px;
	  }
}