
		  @import "src/styles-theme/variables.scss";
		  


.profile-id-check-main {
    display: flex;
    flex-wrap: wrap;
    background-color: $color-grey-8;
    .profile-id-item {
        margin: $size-16 $size-40;
    }
}