
		  @import "src/styles-theme/variables.scss";
		  


.profile-tag {
    border-radius: 5px;
    height: $size-24;
    margin-right: $size-8;
    margin-top: $size-8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 10px;
        margin-right: $size-8;
    }
    img {
        width: 10px;
        height: 10px;
    }

}

.urgent-profile-tag {
    padding: 4px $size-16;
    background-color: $primary-color;
    p {
        background-color: $primary-color;
        color: $color-white;
    }
}

.suspended-profile-tag {
    padding: 4px $size-16;
    background-color: $color-red-font;

    p {
        background-color: $color-red-font;
        color: $color-white;
        cursor: help;
    }
}