
		  @import "src/styles-theme/variables.scss";
		  


.forgot-password-info {
    width: 373px;
    margin-bottom: $size-24;
    margin-top: -$size-16;

    p {
        color: $color-grey-2;
        font-size: 10px;
        line-height: 22px;
    }

    @media only screen and (max-width: 600px) {
        width: 300px;
    }
    @media only screen and (max-width: 340px) {
        width: 280px;
    }
}