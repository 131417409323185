
		  @import "src/styles-theme/variables.scss";
		  
.yoti-badge {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
	border: 1px solid $color-grey-3;
	border-radius: 10px;

	.yoti-logo {
		height: 30px;
	}

	.yoti-badge-text {
		font-size: 12px;
		font-weight: 500;
	}

	.yoti-badge-info {
		padding-left: 10px;
		padding-right: 5px;
	}
}

.in-progress-badge,
.processing-badge {
	background-color: $color-white;
}

.approved-badge {
	background-color: #CFF7D3;
}

.rejected-badge,
.abandoned-badge,
.expired-badge {
	background-color: #FDD3D0;
}