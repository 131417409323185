
		  @import "src/styles-theme/variables.scss";
		  
.external-profile-sidebar-container {
	margin-top: 62px;
	padding: 1rem;
	overflow-y: scroll;
	width: 250px;
	
	section {
		background-color: white;
		border-radius: 5px;
		margin: 0 0 1rem 0;
		padding: 1rem;
	}

	.external-profile-sidebar-worker-declaration-link {
		background: none;
		border: none;
		color: $primary-color;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		outline: none;
		text-decoration: underline;
	}

	.external-profile-sidebar-view-certificate-limited {
		align-items: center;
		display: flex;
		margin-top: -10px;
		margin-bottom: 15px;
	}

	.external-profile-sidebar-view-certificate-full {
		align-items: center;
		display: flex;
		margin-top: 0px;
		margin-bottom: 15px;
	}

	.external-profile-sidebar-view-report {
		align-items: center;
		display: flex;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.external-profile-sidebar-view-links {
		background: none;
		border: none;
		color: $primary-color;
		cursor: pointer;
		display: inline;
		font-size: 16px;
		font-weight: 400;
		outline: none;
		text-decoration: underline;
	}

	.external-profile-sidebar-email-container {
		width: 205px;
		word-wrap: break-word;
		
		.external-profile-sidebar-email {
			color: #485BEB;
			font-size: 14px;
			font-weight: 500;
		}
	}
	
	.blue-info-icon {
		cursor: pointer;
		margin-left: 8px;
	}

	.days-left-container {
		align-items: center;
		display: flex;
		margin-top: -15px;
		margin-bottom: -10px;
		
		.days-left {
			background-color: $color-yellow;
			border-radius: 10px;
			margin: $size-16 0 $size-24 0;
			padding: 5px 10px;
			width: $size-64;
			
			p {
				color: $color-yellow-font;
				font-size: 12px;
				font-weight: 500;				
			}
		}

		.yellow-info-icon {
			cursor: pointer;
			margin-left: 8px;
			margin-top: -9px;		
		}
	}

	.external-profile-sidebar-divider-line {
		border-radius: 5px;
		border-top: solid 1px #D0D8E0;
		height: 1px;
		opacity: 0.4;
	}

	.external-profile-sidebar-collapsible-container {
		align-items: center;
		background-color: white;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
		
		.chevron {
			cursor: pointer;
		}

		.external-profile-sidebar-collapsible-text {	
			color: #9AA5B1;
			cursor: pointer;
			font-size: 12px;
			font-weight: 500;
		}
	}
}
