
		  @import "src/styles-theme/variables.scss";
		  
.dashboard-sub-menu-search {
    display: flex;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    
    .dashboard-sub-menu-search-input {
        margin-right: $size-8;
        margin-left: $size-16;
    }
    
}

@keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}
