
		  @import "src/styles-theme/variables.scss";
		  
.external-dashboard-table-container {
	overflow-y: scroll;
	max-height: 50vh;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: 14px;
		width: 100%;
	}

	thead {
		background-color: #ffffff;
		// th:first-child {
		// 	width: 25%
		// }
	}

	tbody {
		tr {
			&:nth-of-type(odd) {
				background-color: #f6f6f6;
			}

			&:nth-of-type(even) {
				background-color: #ffffff;
			}
		}
	}

	td img {
		//width: 150px;
		text-align: center;
		vertical-align: middle;
	}

	th {
		color: #9aa5b1;
		font-weight: 500;
	}

	td,
	th {
		border-bottom: 1px white solid;
		text-align: left;
		padding: 8px;
		width: 16.6667%;
	}

	a {
		color: $primary-color;
	}

	.no-data {
		text-align: center;
	}

	.header-arrow {
		width: 12px;
		cursor: pointer;
		margin-left: 8px;
		margin-bottom: -1px;
	}

	// .dashboard-table-header {
	// 	margin-bottom: 10px;
	// }

	.status-container {
		margin: 0;
		max-height: 25px;
		width: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 15px;
		overflow: hidden;
		font-size: 14px;
		padding: 2px 0;
		font-weight: 500;

		.status-awaiting {
			color: #9aa5b1;
		}
		.status-in-progress {
			color: #485beb;
		}
		.status-limited {
			color: #e6a501;
		}
		.status-full {
			color: #09ad99;
		}
		.status-cancelled {
			color: #ec8079;
		}
	}

	.upload-icon {
		width: 15px;
		margin-right: 5px;
	}

	.orange-row-bullet::before {
		content: '\2022'; /* the Unicode character for a bullet point */
		color: #fc8e28;
	}

	.orange-row-bullet {
		text-align: left;
		font-size: inherit;
		font-weight: inherit;
		font-family: inherit;
		font-style: inherit;
		color: inherit;
	}

	.edit-icon {
		width: 35px;
		height: 35px;
		cursor: pointer;
		margin-right: 8px;

		&:active {
			opacity: 0.75;
		}
	}

	.delete-icon {
		width: 20px;
		height: 20px;
		cursor: pointer;
		margin-left: 8px;

		&:active {
			opacity: 0.75;
		}
	}
}
