
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-row-content {
	display: flex;
	align-items: center;
	width: 100%;
	background-color: $color-white;
	// max-height: $size-80;
	padding: 15px 0;
	margin-bottom: 4px;
	cursor: auto;
	&.dashboard-cursor-pointer {
		cursor: pointer;
	}

	.text-left {
		align-self: flex-start;
		p {
			font-weight: 600;
		}
	}

	.tags {
		margin-left: $size-24;
	}
	.col {
		display: flex;
		align-items: center;
	}
	.col-sm {
		min-width: 120px;
		max-width: 120px;
		justify-content: center;
		@media only screen and (max-width: 500px) {
			min-width: 80px;
			max-width: 80px;
		}
	}

	.compliance-status-indicator {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5px;

		.status-label {
			font-size: 12px;
		}

		.compliance-container {
			margin: 0;
			max-height: 25px;
			width: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 15px;
			overflow: hidden;
			font-size: 14px;
			padding: 2px 0;
			font-weight: 500;

			img {
				margin: 0;
				object-fit: cover;
				max-height: 25px;
			}

			.status-letter-F {
				color: #59b3aa;
			}
			.status-letter-LS {
				color: #dda70b;
			}
			.status-letter-N {
				color: #5b5b5b;
			}
		}
	}

	.col-md {
		min-width: 130px;
		max-width: 130px;
		justify-content: center;
	}
	.ambassador {
		margin-left: $size-32;
	}
	.email {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
	}
	.email-text {
		font-size: 12px;
	}

	.col-lg {
		min-width: 240px;
		max-width: 240px;
		@media only screen and (max-width: 500px) {
			min-width: 190px;
			max-width: 190px;
		}
	}
	.pause-cancel-tag {
		display: flex;
		align-items: center;
		cursor: help;
		z-index: 999;
		position: relative;

		p {
			font-size: 14px;
			font-weight: $font_semi_bold;
			&.paused {
				color: $primary-color;
			}
		}
		img {
			margin-right: $size-8;
			margin-left: 12px;
		}
	}

	.submitted-date {
		.date-text {
			color: $color-black;
			font-size: 14px;
			font-weight: $font_demi_bold;
		}
		.submitted-no-data {
			margin-left: $size-8;
			color: $color-grey-3;
		}

		.days-ago {
			font-size: 12px;
			font-weight: $font_semi_bold;
			color: $color-grey-3;
		}
	}
	.center {
		display: flex;
		justify-content: center;
	}

	@media only screen and (max-width: 720px) {
		.hide {
			display: none;
		}
	}
}
