
		  @import "src/styles-theme/variables.scss";
		  


.suspended-modal {
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
    .subheading {
        margin-left: 12px;
    }
    p {
        font-size: 14px;
    }
    .suspended-item {
        display: flex;
        align-items: center;
        margin: $size-16 $size-8;
        img {
            margin-right: $size-8;
            cursor: pointer;
        }
    }

    .other-text {
        text-align: center;
       
        margin-bottom: $size-16;
        padding: 0 $size-40;
        textarea {
            width: 100%;
            height: 160px;
            background-color: $color-grey-8;
            border: 1px solid darken($color-grey-8, 5%);
            outline: none;
            border-radius: 4px;
            font-size: 14px;
            padding: 8px;
            color: $color-grey-1;
            font-size: 14px;
        }
    }

    .unsuspend {
        padding-left: $size-16;
        p {
            margin-bottom: $size-16;
        }
    }

    .suspend-button-section {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: flex-end;
        
    }

  

    
}