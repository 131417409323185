
		  @import "src/styles-theme/variables.scss";
		  


.breakdown-item {
    margin: 20px 0;
    
    .item {
      label {
        margin: 0;
        padding: 0;
      }
      display: flex;
      align-items: center;
  
      &.first {
          position: relative;
          left: 2px;
      }
    
    }
  }
  .breakdown-item-container {
    .breakdown-item {
      .item {
        height: 32px!important; 
        p {
            font-size: 12px;
        }
      }
      .sub-item {
        margin-left: 24px;
        transform: translateY(2px);
       
      }
      .sub-item-last {
        height: 2px;
        width: 24px;
        background-color: $primary-color;
        position: relative;
        transform: translateX(13px) translateY(19px);
        z-index: 0;
      }
      .vertical-first {
        height: 32px;
        width: 2px;
        background-color: $primary-color;
        position: relative;
        transform: translateX(-11px) translateY(-17px);
        z-index: 0;
      }
      .title {
        margin-left: 2px;
      }
      img {
        z-index: 1;
      }
      .vertical {
        height: 32px;
        width: 2px;
        background-color: $primary-color;
        position: relative;
        right: -13px;
        bottom: 13px;
        z-index: 0;
      }
      .text {
        display: flex;
        align-items: center;
        position: relative;
        bottom: 10px;
        right: 13px;
      }
      .horizontal {
        height: 2px;
        width: 20px;
        background-color: $primary-color;
        margin-right: 2px;
        z-index: 0;
        transform: translateX(-15px);
        
      }
  
      .list-text {
        transform: translateX(-15px);
        &.help {
            cursor: help;
        }
      }
     
    }
  }