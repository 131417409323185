
		  @import "src/styles-theme/variables.scss";
		  
.toggle-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 150px;
	height: 25px;
	background-color: $color-grey-7;
	border-radius: 20px;
	position: relative;
	padding: 5px;
	cursor: pointer;
  }
  
  .toggle-option {
	flex: 1;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	z-index: 1;
  }
  
  .toggle-indicator {
	position: absolute;
	width: 50%;
	height: 80%;
	background-color: $color-primary-lighter;
	border-radius: 20px;
	transition: transform 0.3s ease;
	z-index: 0;
  }
  
  .toggle-indicator.left {
	transform: translateX(0);
  }
  
  .toggle-indicator.right {
	transform: translateX(90%);
  }
  