
		  @import "src/styles-theme/variables.scss";
		  
.rtw-manual-image-wrapper{
	text-align: center;
	img{
		width: 100%;
		max-width: 500px;
		aspect-ratio: 16/9;
		object-fit: cover;
		border-radius: $size-14;
	}
}