
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-header {
	margin-top: 20px;
    background-color: $color-white;
    border-bottom: 1px solid $color-grey-6;
    height: $size-96;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 $size-24;


    .dashboard-header-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .dashboard-header-heading {
            @media only screen and (max-width: 700px) {
                width: 100%;
                margin-bottom: $size-16;
            }
        }
        .dashboard-header-actions {
            display: flex;
        }
        .dashboard-header-item-container {
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: $size-40;
            &.search-container {
                @media only screen and (max-width: 1300px) {
                    margin-left: -16px;
                }
            }
            &.hide {
                @media only screen and (max-width: 700px) {
                    display: none;
                }
            }
            &.filter-mobile {
                cursor: pointer;
                @media only screen and (min-width: 700px) {
                    display: none;
                }
            }

            .filters {
                display: flex;
                align-items: center;
                background-color: $color-grey-8;
                border-radius: 5px;
                height: $size-40;
                padding: 0 $size-16;
                cursor: pointer;
                margin-top: 4px;
                p {
                    color: $primary-color;
                    margin-left: $size-8;
                    font-size: 14px;
                    font-weight: $font-demi_bold;
                }
            }

        }
    }

    h2 {
        color: $primary-color;
    }
    p {
       
        color: $color-grey-3;
    }
}
