@import "./variables.scss";

//FONT
h1,
h2,
h3,
h4,
h5,
p,
li,
input,
textarea {
	color: $color_grey_1;
	font-family: $font_family;
	font-weight: $font_normal;
}

h1 {
	font-size: 28px;
	line-height: $size-40;
	font-weight: $font_demi_bold;
}
h2 {
	font-size: 20px;
	line-height: $size-32;
	font-weight: $font_demi_bold;
}
h3 {
	font-size: 12px;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	line-height: $size-16;
}
h4 {
	font-size: 12px;
	line-height: $size-16;
}
p {
	font-size: $size-16;
	line-height: $size-24;
}

.orka-semi-p {
	font-size: $size-14;
	color: $color-grey-1;
	font-weight: $font_semi_bold;
}

.orka-demi-p {
	font-size: $size-14;
	color: $color-grey-1;
	font-weight: $font_demi_bold;
}

.orka-profile-label {
	font-size: 12px;
	// font-weight: $font_semi_bold;
	color: $color-grey-3;
}
.orka-p-small {
	font-size: 12px;
	color: $color-grey-1;
}
.orka-p-medium {
	font-size: $size-14;
	color: $color-grey-1;
}
.demi-bold{
	font-weight: $font_demi_bold;
}
.demi {
	font-weight: $font_demi_bold;
	color: $color-black;
}
.semi {
	font-weight: $font_semi_bold;
	color: $color-black;
}

.text-right {
	text-align: right;
}
.text-center{
	text-align: center;
}

.orka-profile-value {
	// font-weight: $font_semi_bold;
	// color: $color-black;
	font-size: 14px;
	text-transform: capitalize;
}

.outline-button {
	button {
		background-color: $color_white;
		color: $color_grey_1;
		border: 1px solid $color-grey-4;
	}
}

.orka-btn {
	background-color: $primary-color;
	color: white;
	border-radius: 4px;
	border: none;
	outline: none;
	font-size: $size-16;
	font-weight: $button_font_weight;
	color: $color_white;
	display: inline-block;
	cursor: pointer;
	min-width: $button_width;
	height: $button_height;
}

.orka-input {
	font-size: $form_input_font_size;
	color: $color_grey_1;
	padding: 4px 10px;
}

.na {
	color: $color-grey-4;
}

.grey-text {
	color: $color-grey-4;
}
.color-primary {
	color: $primary-color;
}
.padding-24 {
	padding: $size-24;
}
.margin-top-4 {
	margin-top: 4px;
}
.margin-bottom-4 {
	margin-bottom: 4px;
}
.margin-top-8 {
	margin-top: $size-8;
}
.margin-bottom-8 {
	margin-bottom: $size-8;
}

.margin-top-12 {
	margin-top: 12px;
}

.margin-bottom-16 {
	margin-bottom: $size-16;
}

.margin-top-16 {
	margin-top: $size-16;
}

.margin-right-24 {
	margin-right: $size-24;
}

.margin-bottom-24 {
	margin-bottom: $size-24;
}

.margin-top-24 {
	margin-top: $size-24;
}

.margin-bottom-32 {
	margin-bottom: $size-32;
}

.margin-top-32 {
	margin-top: $size-32;
}

.margin-bottom-40 {
	margin-bottom: $size-40;
}

.margin-top-40 {
	margin-top: $size-40;
}

.aligned-flex {
	display: flex;
	align-items: center;
}

.orka-link {
	color: $primary-color;
	background: none;
	border: none;
	border-bottom: 1px solid $primary-color;
	outline: none;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;
	text-decoration: none;
}

.tag {
	height: $size-32;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: $font_semi_bold;
	font-size: 12px;
	outline: none;
	border: none;
	border-radius: 4px;
	padding: 6px 9px 6px 9px;
	width: $size-120;
}

.tooltip {
	cursor: help;
}

.modal-button {
	position: fixed;
	bottom: $size-24;
	right: $size-24;
}

// modal animations

.my-node-enter {
	opacity: 0;
}
.my-node-enter-active {
	opacity: 1;
	transition: opacity 100ms ease-in-out;
}
.my-node-exit {
	opacity: 1;
}
.my-node-exit-active {
	opacity: 0;
	transition: opacity 100ms ease-in-out;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	/*use inset box-shadow to cover background-color*/
	-webkit-text-fill-color: $color-grey-1 !important;
	/*use text fill color to cover font color*/
}

@media only screen and (max-width: 900px) {
	.tablet-hide {
		display: none;
	}
}
@media only screen and (max-width: 500px) {
	.mobile-hide {
		display: none;
	}
}

.text-left {
	justify-content: flex-start !important;
}

div[role="link"] {
	&:focus {
		border: 1px solid $primary-color;
	}
}

.inline-buttons {
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	width: calc(100% - 32px);
	margin-top: 32px;
	div:nth-child(1) > button {
		background-color: $color-white;
		margin-right: $size-16;
		border: 1px solid $color-grey-1;
		color: $color-grey-1;
	}
}
