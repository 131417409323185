
		  @import "src/styles-theme/variables.scss";
		  



.f2f-dropdown-section {
    padding: $size-24;
    padding-bottom: $size-40;
    background-color: $color_grey_8;
    margin-left: $size-72-5;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
}

.ambassador-details-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    padding: $size-24;
    padding-bottom: 0;
    background-color: $color_grey_8;
    margin-left: $size-72-5;
    margin-top: 4px;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
    .f2f-details {
        display: flex;
        margin-right: $size-16;
        width: 100%;
     
        
        .rtw-item {
            width: 50%;

        }
    }
    .f2f-edit {
        width: $size-16;
        margin-right: $size-16;
        img {
            cursor: pointer;
            width: 14px;
            height: auto;
        }
    }
}