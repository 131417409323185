
		  @import "src/styles-theme/variables.scss";
		  


.risk-container {
    margin-left: $size-72-5;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }

    // .risk-items-list {
    //     background-color: $color_grey_8;
    //     padding: $size-16 $size-24;  
    // }

    .add-risk-btn {
        margin-top: 4px;
        display: flex;
        background-color: $color_grey_8;
        padding: $size-16 $size-24;
        
        button {
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;
            color: $primary-color;
            text-decoration: underline;
            text-underline-position: under;
            font-size: 14px;
            font-weight: $font_semi_bold;
            

        }
      
    }
}