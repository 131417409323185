
		  @import "src/styles-theme/variables.scss";
		  
.external-sidebar-container {
	height: 100%;
	width: 298px;
	overflow-y: scroll;
	border: 1px solid $primary-color;
	background-color: $primary-color;
	transition: 0.3s linear;
	z-index: 1;
	left: 0;
	&.active {
        width: 65px;
    }
	@media only screen and (max-width: 720px) {
		display: none;
	}
	&::-webkit-scrollbar {
		width: 0px;
	}
	.search-container {
		padding-top: $size-24;
		.small-search {
			display: flex;
			justify-content: center;
			-webkit-animation: fadein 1s;
			/* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 1s;
			/* Firefox < 16 */
			-ms-animation: fadein 1s;
			/* Internet Explorer */
			-o-animation: fadein 1s;
			/* Opera < 12.1 */
			animation: fadein 1s;
			img {
				cursor: pointer;
			}
		}
	}
	.sidebar-header-large {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $size-16;
		img {
			height: $size-32;
			width: auto;
			-webkit-animation: fadein 1s;
			/* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 1s;
			/* Firefox < 16 */
			-ms-animation: fadein 1s;
			/* Internet Explorer */
			-o-animation: fadein 1s;
			/* Opera < 12.1 */
			animation: fadein 1s;
			cursor: pointer;
		}
		.avatar {
			background-color: $color-primary-lighter;
			color: $color-white;
			width: $size-40;
			height: $size-40;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			-webkit-animation: fadein 1s;
			/* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 1s;
			/* Firefox < 16 */
			-ms-animation: fadein 1s;
			/* Internet Explorer */
			-o-animation: fadein 1s;
			/* Opera < 12.1 */
			animation: fadein 1s;
		}
	}
	.active-tab {
		background-color: $color-primary-lighter;
		margin: 10px;
	}
	.sidebar-header-small {
		display: flex;
		justify-content: center;
		margin: $size-24 auto;
		-webkit-animation: fadein 1s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1s;
		/* Firefox < 16 */
		-ms-animation: fadein 1s;
		/* Internet Explorer */
		-o-animation: fadein 1s;
		/* Opera < 12.1 */
		animation: fadein 1s;
		img {
			cursor: pointer;
		}
	}
	.sidebar-action-lg {
		margin-left: $size-16;
		margin-bottom: $size-16;
		display: flex;
		cursor: pointer;
		padding-top: $size-24;
		align-items: center;
		-webkit-animation: fadein 1s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1s;
		/* Firefox < 16 */
		-ms-animation: fadein 1s;
		/* Internet Explorer */
		-o-animation: fadein 1s;
		/* Opera < 12.1 */
		animation: fadein 1s;
		img {
			width: 20px !important;
			height: auto;
			margin-right: 20px;

		}
		.logout-txt {
			color: $color-red-font;
			font-weight: 400;
			font-size: $size-14;

		}
		.link-text {
            font-weight: 400;
            font-size: $size-14;
            color: $color-white;
        }
		.blue-link-text {
			font-weight: 400;
			font-size: $size-14;
			color: $color-primary-lighter;
		}
	}
	.sidebar-action-sm {
		width: 100%;
		text-align: center;
		cursor: pointer;
		margin-bottom: $size-16;
		padding-top: $size-24;
		-webkit-animation: fadein 1s;
		/* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1s;
		/* Firefox < 16 */
		-ms-animation: fadein 1s;
		/* Internet Explorer */
		-o-animation: fadein 1s;
		/* Opera < 12.1 */
		animation: fadein 1s;
		img {
			width: 20px;
			height: 20px;
		}

	}
	.toggle {
		width: inherit;
		text-align: center;
		position: fixed;
		padding-bottom: $size-8;
		bottom: 18px;
		.toggle-sidebar {
			background-color: transparent;
			border: none;
			img {
				cursor: pointer;
			}
		}
	}
	.spacer {
		height: $size-96;
	}
	.inner-sidebar-container {
		margin: $size-8 $size-16 $size-24 $size-16;
		-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 1s; /* Firefox < 16 */
		-ms-animation: fadein 1s; /* Internet Explorer */
		-o-animation: fadein 1s; /* Opera < 12.1 */
		animation: fadein 1s;
		.inner-sidebar-info-container {
			margin-top: $size-24;
			.inner-sidebar-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: $size-16;
				-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
				-moz-animation: fadein 1s; /* Firefox < 16 */
				-ms-animation: fadein 1s; /* Internet Explorer */
				-o-animation: fadein 1s; /* Opera < 12.1 */
				animation: fadein 1s;
				cursor: pointer;
				.inner-sidebar-item-info {
					display: flex;
					align-items: center;
					img {
						width: $size-16;
						height: auto;
						margin-right: $size-24;
					}
					.inner-sidebar-selected {
						color: $color-white;
					}
					.link-text {
						font-weight: 400;
						font-size: $size-14;
						color: $color-white;
					}
					.primary-link-text {
						font-weight: 400;
						font-size: $size-14;
						color: $color-primary-lighter;
					}
				}
			}
			.inner-sidebar-item-sm {
				margin-bottom: $size-24;
				display: flex;
				justify-content: center;
				-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
				-moz-animation: fadein 1s; /* Firefox < 16 */
				-ms-animation: fadein 1s; /* Internet Explorer */
				-o-animation: fadein 1s; /* Opera < 12.1 */
				animation: fadein 1s;
				cursor: pointer;
			}
			.inner-sidebar-amount {
				background-color: $color-grey-8;
				border-radius: 100px;
				padding: 0 10px;
				height: $size-24;
				min-width: $size-40;
				display: flex;
				justify-content: center;
				align-items: center;
				p {
					font-size: 12px;
					font-weight: $font_demi_bold;
				}
			}
		}
	}

	.border-line {
		border-top: 1px solid #f7f7f7;
		padding-top: 32px;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
