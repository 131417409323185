
		  @import "src/styles-theme/variables.scss";
		  


.licence-section {

    margin-left: $size-72-5;
    // padding: $size-24;
    background-color: $color-grey-8;
    @media only screen and (max-width: 700px) {
        margin-left: unset;
    }
    .licence-item {
        margin-bottom: $size-16;
        p {
            font-weight: $font_semi_bold;
            color: $color-black;
            margin-bottom: 4px;
        }
        .licence-item-label {
            font-size: 12px;
            font-weight: $font_demi_bold;
            color: $color-grey-3;
        }

    }

	.flex-row {
		display: flex;
		gap: 0.5rem;
	}

	.orka-check-file-bucket {
		border-bottom: 4px solid #FFFFFF;
		padding: $size-16 $size-24;
	}
}

