
		  @import "src/styles-theme/variables.scss";
		  
.bucket-container {
    margin: $size-8 $size-16 $size-24 $size-16;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
    .bucket-dropdown-open {
        display: flex;
        align-items: center;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        padding-top: $size-14;   
        img {
            margin-right: $size-24;
            width: 14px;
            height: 14px;
            margin-left: 3px;
            cursor: pointer;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: $color-white;
            cursor: pointer;
        }
    }
    .bucket-dropdown-closed {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: $size-14;   
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        img {
            margin-right: unset;
            width: 14px;
            height: 14px;
            margin-left: unset;
            cursor: pointer;
        }
    }
	.active-bucket-tab {
		background-color: $color-primary-lighter;
		padding: 10px;
	}	
	.active-bucket-tab-sm {
		color: $color-primary-lighter;
	}
    .bucket-info-container {
        margin-top: $size-24;
        .bucket-item {
            display: flex;
            align-items: center;
            margin-bottom: $size-16;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
            -ms-animation: fadein 1s; /* Internet Explorer */
            -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
            cursor: pointer;
            .bucket-item-info {
                display: flex;
                align-items: center;
                img {
                    width: $size-16;
                    height: auto;
                    margin-right: $size-24;
                }
                .bucket-selected {
                    color: $color-white;
                }
                p {
                    font-weight: 400;
                    font-size: $size-14;
                    color: $color-white;
                }
            }
        }
        .bucket-item-sm {
            margin-bottom: $size-24;
            display: flex;
            justify-content: center;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
            -ms-animation: fadein 1s; /* Internet Explorer */
            -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
            cursor: pointer;
        }
        .bucket-amount {
            background-color: $color-grey-8;
            border-radius: 100px;
            padding: 0 10px;
            height: $size-24;
            min-width: $size-40;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                font-size: 12px;
                font-weight: $font_demi_bold;
            }
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
