
		  @import "src/styles-theme/variables.scss";
		  


.checklist-component {
    background-color: $color_grey_8;
    margin-left: $size-72-5;
    padding: $size-24 $size-40;
    @media only screen and (max-width: 700px) {
        margin-left: unset;
    }
    .item {
        height: $size-40;
        display: flex;
        align-items: center;
        

        .checklist-item {
            display: flex;
            align-items: center;
            p {
               
                color: $color_grey_1;
                font-size: 14px;
                cursor: help;
            }
           
            
            img {
                margin-right: $size-8;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
    
            &.first {
                position: relative;
                left: 2px;
            }
    
            
        }
        
        
        .bar {
            height: $size-32;
            width: 2px;
            background-color: $primary-color;
            position: relative;
            right: -13px;
            bottom: 20px;
            z-index: 0;
        }

    }
    
}