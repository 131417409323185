
		  @import "src/styles-theme/variables.scss";
		  


.profile-names {
   
    border-top: 1px solid $color-grey-8;
    padding-top: $size-16;
    .name-col {
        margin-bottom: $size-16;
    }
      
}