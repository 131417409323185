
		  @import "src/styles-theme/variables.scss";
		  
.sidebar-container {
    height: 100vh;
    width: 350px;
    overflow-y: scroll;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    transition: 0.3s linear;
    position: fixed;
    z-index: 1000;
    left: 0;
	.sidebar-toggle-switch {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
    @media only screen and (max-width: 720px) {
        display: none;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
    .search-container {
		padding-top: $size-14;
        padding-bottom: $size-24;
        .small-search {
            display: flex;
            justify-content: center;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 1s; /* Firefox < 16 */
            -ms-animation: fadein 1s; /* Internet Explorer */
            -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
            img {
                cursor: pointer;
            }
        }
	}
    .bucket-item {
        display: flex;
        justify-content: space-between;
    }
    .sidebar-header-large {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $size-24 $size-16;
        img {
            height: $size-32;
            width: auto;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 1s; /* Firefox < 16 */
			-ms-animation: fadein 1s; /* Internet Explorer */
			-o-animation: fadein 1s; /* Opera < 12.1 */
			animation: fadein 1s;
            cursor: pointer;
        }
        .avatar {
            background-color: $color-primary-lighter;
            color: $color-white;
            width: $size-40;
            height: $size-40;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein 1s; /* Firefox < 16 */
			-ms-animation: fadein 1s; /* Internet Explorer */
			-o-animation: fadein 1s; /* Opera < 12.1 */
			animation: fadein 1s; 
        }
    }
    .sidebar-header-small {
        display: flex;
        justify-content: center;
        margin: $size-24 auto;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        img {
            cursor: pointer;
        }
    }
    .sidebar-action-lg {
		padding: $size-16;
        margin-bottom: $size-16;
        display: flex;
        cursor: pointer;
        align-items: center;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        img {
            width: 20px;
            height: auto;
            margin-right: 20px;
        }
        .logout-txt {
            color: $color-red-font;
            font-weight: 400;
            font-size: $size-14;
        }
        .link-text {
            font-weight: 400;
            font-size: $size-14;
            color: $color-white;
        }
    }
	.active-tab {
		background-color: $color-primary-lighter;
		margin: 10px;
	}
    .sidebar-action-sm {
        width: 100%;
        text-align: center;
        cursor: pointer;
        margin-bottom: $size-16;
        padding-top: $size-24;
        -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
        -o-animation: fadein 1s; /* Opera < 12.1 */
        animation: fadein 1s;
        img {
            width: 20px;
            height: 20px;
        } 
    }
    .toggle {
        width: inherit;
        text-align: center;
        position: fixed;
		padding-top: $size-16;
        padding-bottom: $size-16;
        bottom: 1px;
        background-color: $primary-color;
        .toggle-sidebar {
            background-color: transparent;
            border: none;
            img {
                cursor: pointer;
            }   
        }
    }
    .spacer {
        height: $size-96;
    }
}

@keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    20% {  opacity: 0;}
    100%  { opacity: 1; }
}
