
		  @import "src/styles-theme/variables.scss";
		  


.clearance-info {
    padding-top: 2px;
    .display-edit {
        display: flex;
        align-items: flex-start;
        img {
            margin-left: $size-8;
            margin-top: 2px;
            height: 14px;
            width: 14px;
            cursor: pointer;
        }
    }
    .partially-cleared-info {
        .display-edit {
            display: flex;
            align-items: flex-start;
            img {
                margin-left: $size-8;
                margin-top: 2px;
                height: 14px;
                width: 14px;
                cursor: pointer;
            }
        }
     
        .days-left {
            background-color: $color-yellow;
            padding: 5px 10px;
            border-radius: 10px;
            width: $size-64;
            margin: $size-16 0 $size-24 0;;
            p {
                color: $color-yellow-font;
                font-weight: 500;
                font-size: 12px;
            }
        }
}
.view-certificate {
    p {
        cursor: pointer;
        text-decoration: underline;
        text-underline-position: under;
        color: $primary-color;
        font-size: 14px;
        line-height: $size-16;
    }
}
    
}