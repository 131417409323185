
		  @import "src/styles-theme/variables.scss";
		  


.profile-last-chased {
	margin-top: 10px;
    .last-chased-date {
        width: $size-120;
        background-color: $color-white;
        height: $size-32;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p {
            font-size: 12px;
            color: $color-grey-1;
            font-weight: $font_semi_bold;
        }
    }
}