
		  @import "src/styles-theme/variables.scss";
		  
.external-user-profile-tab-container {
	height: 90%;
	scroll-padding-top: 3.5rem;
	overflow-y: scroll;
	
	.external-user-profile-tabs-header {
		background-color: white;
		border-radius: 5px;
		color: #9AA5B1;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		height: 50px;
		padding: 0 1rem;
		position: sticky;
		top: 0;
		z-index: 999;
	}
	
	.external-user-profile-tabs-header-tab {
		align-items: center;
		cursor: pointer;
		display: flex;
		padding: 10px 20px;
		transition: 0.2s ease-in-out;
		&.active {
			border-bottom: 2px solid $color-primary;
			bottom: -5px;
			color: #4A4A4A;
			font-weight: 600;
		}
	}

	.limited-tabs {
		justify-content: flex-start;
	}

	.all-tabs {
		justify-content: space-between;
	}

	section {
		background-color: white;
		border-radius: 5px;
		margin: 0 0 1rem 0;
		padding: 1rem;
	}
}
