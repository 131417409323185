
		  @import "src/styles-theme/variables.scss";
		  


.certificate-info-container {
   
    margin-bottom: $size-8;
    .cert-section-title {
        background-color:$color-grey-8;
        margin-bottom: $size-16;
        padding-left: $size-16;
        p {
            font-size: 10px;
            color: $color-grey-3;
            font-weight: $font_semi_bold;

        }
    }
    .certificate-info-container-main {
        display: flex;
        padding-left: $size-24;
        .certificate-info-container-side {
            width: 50%;
        }
    }
    

}