
		  @import "src/styles-theme/variables.scss";
		  


  .file-assigned-to {
    border-top: 1px solid $color-grey-8;
    padding-top: $size-8;
    margin-left: $size-24;
  }


