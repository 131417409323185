
		  @import "src/styles-theme/variables.scss";
		  
.identity-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 5px 25px 5px;

	.identity-header-title {
		font-size: 18px;
		font-weight: 500;
	}

	.identity-header-right {
		display: flex;
		flex-direction: row;
		justify-content: right;
		align-items: center;
		width: 150px;

		.chevron {
			cursor: pointer;
			width: 15px;
		}
	}

	.status-tab {
		padding: 5px;
		background-color: #FEE9E7;
		color: #C00F0C;
		border-radius: 5px;
		margin-right: 10px;
	}
}

.sub-section-container {
	padding: 15px !important;
	margin: 15px;
	border: 1px solid $color-grey-5;
	border-radius: 10px;

	.sub-section-title-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		.sub-section-title {
			color: $color-grey-3;
			font-size: 16px;
			font-weight: 500;
		}
	}

	.sub-section-info-table {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		width: 100%;
		text-align: start;
		padding-bottom: 10px;

		.info-table-title {
			font-weight: 500;
			color: $color-grey-3;
		}
	}

	.sub-section-identity-confidence-level {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 0;

		.sub-section-identity-confidence-level-icon {
			padding-right: 10px;
		}
	}

	.sub-section-identity-container {
		padding: 24px;

		.sub-section-identity-document {
			padding: 32px;
		}

		.sub-section-share-code {
			display: flex;
			justify-content: space-between;

			.share-code-title {
				color: $color-grey-2;
			}
			.sub-section-share-code-copy {
				cursor: copy;
				margin-left: 5px;
			}
		}

		.sub-section-share-code-link {
			display: flex;
			justify-content: end;
			a {
				display: flex;
				color: $color-primary;
				font-weight: 500;
				cursor: pointer;
			}
			.sub-section-share-code-goto {
				margin-left: 10px;
				width: 15px;
			}
		}
	}

	.sub-section-non-submission-container {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 100px;
		color: $color-grey-5;
	}
}

.view-container {

	.status {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 12px;
		margin-right: 0.5rem;

		.risk-flag {
			text-align: right;
		}

		.status-icon {
			margin-right: 0.25rem;
		}

		.status-text {
			margin-bottom: 0.1rem;
			margin-top: 0.16rem;
		}

		.status-text-complete {
			color: #485beb;
		}

		.status-text-partially-verified {
			color: #09ad99;
		}

		.status-text-pending-review {
			color: #f8c96a;
		}
	}

	.user-profile-section-title {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 20px;
	}

	.user-profile-section-divider-line {
		border-radius: 5px;
		border-top: solid 1px #d0d8e0;
		height: 1px;
		margin-top: 20px;
		opacity: 0.4;
	}

	.user-profile-section-main {
		margin-top: 0.5rem;

		.user-profile-section-main-text {
			text-align: center;
			padding: 40px 0;
			height: 40px;
			border: 1px solid $color-grey-4;
			border-radius: 10px;
		}

		.external-user-profile-sub-section-container {
			padding: 15px !important;
			margin: 15px;
			border: 1px solid $color-grey-5;
			border-radius: 10px;

			.external-user-profile-sub-section-title-container {
				align-items: center;
				cursor: pointer;
				display: flex;

				.external-user-profile-sub-section-icon {
					margin-right: 10px;
				}

				.external-user-profile-sub-section-risk-icon {
					margin-right: 14px;
					width: 12px;
				}

				.external-user-profile-sub-section-shrink-icon {
					width: 15px;
				}

				.external-user-profile-sub-section-title {
					font-size: 16px;
					font-weight: 600;
				}

				.external-user-profile-sub-section-risk-title {
					font-size: 16px;
					font-weight: 600;
					color: #ec8079;
					text-decoration: underline;
				}

				.external-user-profile-sub-section-title-margin {
					margin-left: 3px;
				}

				.external-user-profile-sub-section-dates {
					font-size: 14px;
					font-weight: 500;
					margin-right: 10px;
					margin-left: auto;
				}

				.external-user-profile-sub-section-status {
					font-size: 12px;
					font-weight: 500;
					margin-right: 10px;
					margin-left: auto;
				}

				.sub-chevron {
					cursor: pointer;
					margin-top: -1px;
				}
			}

			.external-user-profile-sub-section-subtitle-container {
				align-items: center;
				display: flex;
				justify-content: space-between;

				.external-user-profile-sub-section-subtitle {
					font-size: 12px;
					font-weight: 600;
					margin-left: 26px;
				}

				.external-user-profile-sub-section-subtitle-risk-note {
					color: #ec8079;
					font-size: 12px;
					font-weight: 600;
					margin-left: 26px;
				}

				.external-user-profile-sub-section-subtitle-risk-assigned-note {
					color: #ec8079;
					font-size: 12px;
					font-weight: 600;
					margin-bottom: -2px;
					margin-left: 26px;
					margin-top: 2px;
				}

				.external-user-profile-sub-section-status-text {
					font-size: 12px;
					font-weight: 500;
					margin-right: 26px;
				}
			}

			.external-user-profile-sub-section-identity-subtitle-container {
				display: flex;
				align-items: center;

				.external-user-profile-sub-section-identity-subtitle {
					font-size: 12px;
					font-weight: 600;
					margin-left: 26px;
				}

				.external-user-profile-sub-section-identity-body {
					color: #4a4a4a;
					font-size: 12px;
					font-weight: 500;
					margin-left: 3px;
				}
			}

			.external-user-profile-sub-section-body {
				margin-top: 0.5rem;
				margin-left: 26px;

				.external-user-profile-sub-section-text-light {
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 10px;
					margin-top: -8px;
					opacity: 0.5;
				}

				.external-user-profile-sub-section-job-title {
					color: #4a4a4a;
					font-size: 12px;
					font-weight: 500;
					margin-top: -7px;
					max-width: 80%;

					.external-user-profile-sub-section-career-break {
						font-size: 12px;
						font-weight: 600;
						margin-top: 15px;
					}
				}

				.external-user-profile-sub-section-leaving {
					font-size: 12px;
					font-weight: 600;
					margin-top: 15px;
				}

				.external-user-profile-sub-section-leaving-text {
					font-size: 12px;
					font-weight: 600;
					opacity: 0.5;
				}

				.external-user-profile-sub-section-reference-title {
					font-size: 12px;
					font-weight: 600;
					margin-top: 15px;
				}

				.external-user-profile-sub-section-reference {

					p,
					a {
						color: #485beb;
						font-size: 12px;
						font-weight: 500;
					}
				}

				.external-user-profile-sub-section-file-container {
					margin-right: 26px;
					margin-top: 15px;

					.external-user-profile-sub-section-file-title {
						font-size: 12px;
						font-weight: 600;
					}

					.external-user-profile-sub-section-file-text {
						color: #4a4a4a;
						font-size: 12px;
						font-weight: 500;
					}

					.external-user-profile-sub-section-file-content {
						align-items: center;
						display: flex;
						margin-top: 10px;
					}

					.external-user-profile-sub-section-file-link {
						align-items: center;
						color: #4a4a4a;
						display: flex;
						text-decoration: none;
					}

					.external-user-profile-sub-section-file-icon {
						margin-right: 5px;
					}

					.external-user-profile-sub-section-file-number {
						color: #4a4a4a;
						font-size: 12px;
						font-weight: 500;
						margin-left: 5px;
					}

					.external-user-profile-sub-section-file-status {
						margin-left: auto;
						color: #4a4a4a;
						font-size: 12px;
						font-weight: 500;
					}
				}

				.external-user-profile-sub-section-disclosure-container {
					margin-bottom: 15px;

					.external-user-profile-sub-section-disclosure {
						color: #4a4a4a;
						font-size: 12px;
						font-weight: 500;
						margin-top: -7px;
						text-decoration: underline;
					}

					.external-user-profile-sub-section-disclosure-title {
						color: #4a4a4a;
						display: inline-block;
						font-size: 12px;
						font-weight: 500;
					}

					.external-user-profile-sub-section-disclosure-text {
						color: #4a4a4a;
						display: inline-block;
						font-size: 12px;
						font-weight: 500;
						margin-left: 2px;
					}
				}

				.external-user-profile-sub-section-disclosure-note {
					color: #4a4a4a;
					font-size: 12px;
					font-weight: 500;
				}
			}

			.external-user-profile-sub-section-body-risk {
				display: flex;
				margin-top: 0.5rem;
				margin-left: 26px;

				.external-user-profile-sub-section-text-risk-container {
					display: block;

					.external-user-profile-sub-section-text-light-risk {
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 10px;
						max-width: 400px;
						opacity: 0.5;
					}
				}

				.external-user-profile-sub-section-review-risk {
					margin-left: auto;
					margin-right: 10px;
					margin-top: 42px;
				}
			}

			.external-user-profile-sub-section-external-check-title {
				font-size: 16px;
				font-weight: 600;
			}

			.external-user-profile-sub-section-name-history-container {
				display: flex;
				flex-direction: row;

				.external-user-profile-sub-section-subtitle-name-history-container {
					display: flex;
					flex-direction: column;

					.external-user-profile-sub-section-name-history-subtitle {
						font-size: 12px;
						font-weight: 600;
						margin-left: 26px;
					}

					.external-user-profile-sub-section-name-history-body {
						font-size: 12px;
						font-weight: 600;
						margin-top: 5px;
						margin-left: 26px;
						opacity: 0.5;
					}
				}
			}

			.external-user-profile-sub-section-identity-container {
				padding: 24px;
			}

			.profile-document-details {
				margin-left: unset !important;
			}
		}

		.external-user-profile-sub-section-risk-margin {
			margin-top: 10px;
		}

		.external-user-profile-sub-section-licence-container {
			display: flex;
			flex-direction: row;
			padding: 0 !important;
			padding-top: 10px !important;

			.external-user-profile-sub-section-licence-details-container {
				margin-top: 80px;
				width: 60%;

				.external-user-profile-sub-section-licence-title-container {
					align-items: center;
					cursor: pointer;
					display: flex;

					.external-user-profile-sub-section-licence-icon {
						margin-right: 10px;
					}

					.external-user-profile-sub-section-licence-title {
						font-size: 16px;
						font-weight: 600;
						text-decoration: underline;
					}
				}

				.external-user-profile-sub-section-licence-subtitle-container {
					align-items: center;
					display: flex;
					justify-content: space-between;

					.external-user-profile-sub-section-licence-subtitle {
						font-size: 12px;
						font-weight: 600;
						margin-left: 28px;
					}
				}

				.external-user-profile-sub-section-licence-body {
					margin-top: 0.5rem;
					margin-left: 26px;

					.external-user-profile-sub-section-licence-text-light {
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 10px;
						margin-left: 2px;
						margin-top: -8px;
						opacity: 0.5;
					}
				}
			}

			.external-user-profile-sub-section-image-container {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 15px;
				width: 40%;

				.external-user-profile-sub-section-tab-container {
					display: flex;
					justify-content: center;
					margin-top: -12px;

					.external-user-profile-sub-section-tab {
						color: #4a4a4a;
						cursor: pointer;
						flex-shrink: 0;
						padding: 8px 16px;
						text-align: center;
					}

					.external-user-profile-sub-section-tab:hover {
						opacity: 0.8;
					}

					.external-user-profile-sub-section-tab.selected {
						border-top: 2px solid #485beb;
						color: #4a4a4a;
						font-weight: 500;
					}
				}

				.external-user-profile-sub-section-licence-image {
					border-radius: 5px;
					height: auto;
					margin-top: 16px;
					width: 300px;
				}
			}

			.external-user-profile-sub-section-licence-no-image {
				font-size: 12px;
				font-weight: 600;
				margin-left: auto;
				margin-right: 10px;
				margin-top: 100px;
				margin-bottom: 100px;
			}
		}
	}

	.user-profile-section-file-container {
		margin-top: 15px;

		.user-profile-section-file-title {
			font-size: 16px;
			font-weight: 600;
			margin-left: 24px;
			text-decoration: underline;
		}

		.user-profile-section-file-text {
			color: #4a4a4a;
			font-size: 12px;
			font-weight: 500;
		}

		.user-profile-section-file-content {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
		}

		.user-profile-section-file-link {
			align-items: center;
			color: #4a4a4a;
			display: flex;
			text-decoration: none;
		}

		.user-profile-section-file-icon {
			margin-left: 26px;
			margin-right: 5px;
		}

		.user-profile-section-file-number {
			color: #4a4a4a;
			font-size: 12px;
			font-weight: 500;
			margin-left: 5px;
		}

		.user-profile-section-file-status {
			color: #4a4a4a;
			font-size: 12px;
			font-weight: 500;
			margin-right: 10px;
		}
	}

	.external-rtw-manual-container {
		background-color: $color-grey-8;
		padding: $size-32;

		@media only screen and (max-width: 700px) {
			margin-left: 0;
		}

		.confidence-level {
			align-items: center;
			display: flex;
			font-weight: $font_normal;
			gap: $size-8;
		}
	}

	.last-verified {
		font-weight: 500;
		font-size: 12px;
		color: #9aa5b1;
		margin-top: 1rem;
	}

	.last-verified-margin {
		margin-left: 26px;
	}

	.remove-pointer {
		cursor: default !important;
	}
}

.identity-container {
	cursor: pointer;
}