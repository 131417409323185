
		  @import "src/styles-theme/variables.scss";
		  


.profile-general-info {
    margin-bottom: $size-16;
    border-top: 1px solid $color-grey-8;
    padding-top: $size-16;
    margin-top: $size-24;
    .profile-general-info-item {
        margin-bottom: $size-16;
        .edit-user {
            display: flex;
            align-items: center;
            margin-top: $size-8;
            padding-bottom: 12px;
            border-bottom: 1px solid $color-grey-8;

            img {
                margin-left: $size-16;
                width: 14px;
                height: 14px;
                cursor: pointer;
                margin-bottom: 4px;
            }
            p {
               color: $color-grey-3;
            }

        }
        .email {
            font-size: 12px;
        }
    
    }
  
}
