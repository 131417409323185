
		  @import "src/styles-theme/variables.scss";
		  


@page {
   
    padding: 10mm;
    margin: 20mm;
    * {
        background-color: white;
    }
    
  }

  @media all {
    .pagebreak {
      display: none;
    }
    
  }

  @media print {
    * {
        background-color: white;
    }
    .page-content {
        background-color: white;
        height: 100%;
        p {
            font-size: 14px;
        }
        .certificate-header {
            .certificate-header-top {
                margin-bottom: 5mm;
                .certificate-header-top-left {
                    img {
                        transform: scale(1.5)translateX(4mm) translateY(1mm);
                    }
                    p {
                        font-size: 18px;
                        transform: translateX(16mm);
                        color: $color-black;
                    }
                } 
                .certificate-header-top-right {
                    img {
                        transform: scale(1.5) translateX(-6mm);
                    }
                }   
                
            }
            .certificate-header-text {
                font-size: 14px;
                line-height: 24px;
              
            }
        } 

        .certificate-info-container {
            padding: 6mm 0 4mm 0;
            .certificate-info-container-main {
                .certificate-info-container-side {
                    .certificate-info-item-container {
                        min-height: 10mm;
                        .label {
                            width: 30mm;
                        }
                    }
                }
                
            }
            .certificate-info-item-container {
                min-height: 10mm;
                .label {
                    width: 30mm;
                }
            }
        }

        .certificate-list-item-container {
            margin-bottom: 14mm;
            .certificate-list-item {
                p {
                    font-size: 14px;
                }
                min-height: 10mm;
            }
            .risk-row-section {
                padding: 1mm 0;
                .risk-row {
                    min-height: 10mm;
                    .risk-title {
                        min-width: 30mm;
                    }
                    p {
                        font-size: 12px;
                    }
                }
                
            }
        }
        .cert-section-title {
            background-color: transparent;
           p {
                font-size: 14px;
           }
            
        }
        .cert-history-container {
            .cert-history-row {
                &.extra-padding {
                    padding-top: 4mm;
                }
                .overlap {
                    font-size: 12px;
                }
            }

            p {
                font-size: 12px;
            }
        }
        .certificate-footer {
            .footer-text {
                margin-bottom: 20mm;
                p {
                    font-size: 12px;
                }
            }
            .footer-images {
                p {
                    font-size: 12px;
                }
                img {
                    transform: scale(1.2) translateX(-4mm);
                }
            }
        }
    }
    .pagebreak {
      page-break-before: always;
    }
  }

.pdf-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: #181818f1;
    height: 100%;
    overflow: hidden;
    transition: 3s ease-in-out;
    .close-certificate {
        position: absolute;
        top: $size-16;
        left: $size-16;
        cursor: pointer;
    }
    .download-certificate {
        position: absolute;
        top: $size-16;
        right: $size-16;
        cursor: pointer;
        display: flex;
        align-items: center;
        .download-text {
            border-bottom: 2px solid $color-white;
            
            margin-left: 6px;
            p {
                color: $color-white;
                line-height: 19px;
            }
        }

        
        img {
            height: 18px;
            margin-top: 2px;
        }

    }

   
    .page-content {
        min-width: $size-304;
        width: 440px;
        max-width: 440px;
        height: 80vh;
        overflow-y: scroll;
        background-color: white;
        position: absolute;
        top: 50%;  
        left: 50%; 
        transform: translate(-50%, -50%);
        padding: $size-32;

      
       
       
      
        &::-webkit-scrollbar {
            width: 0;
        }
       
    }

    @media print {
        width: 100%;
        min-width: 100%;
        margin: 0;
        padding: 0;
    }

    
}