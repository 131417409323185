
		  @import "src/styles-theme/variables.scss";
		  


.certificate-info-item-container {
    display: flex;
    margin-bottom: 8px;
    // width: 80%;
    // margin-right: 80px;
    p {
        font-size: 10px;
    }
    .label {
        width: 80px;
        p {
            color: $color-grey-1;
            
        }
        
        
    }
    .value {
        p {
            font-weight: $font_semi_bold;
            color: $color-black;

        }
    }
}