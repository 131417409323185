
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-page {
  
    background-color: $color-grey-8;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  
}

@keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    50% {  opacity: 0;}
    100%  { opacity: 1; }
}