
		  @import "src/styles-theme/variables.scss";
		  


.rtw-expiry-modal {
    .rtw-expiry-modal-radio-section {
        margin: $size-16 0 $size-40 0;
        
        .rtw-expiry-modal-radio-item {
            margin-bottom: $size-8;
            display: flex;
            align-items: center;
            label {
                margin-right: $size-8;
            }
        }
    }

    .calendar-section {
        margin-bottom: $size-16;
        
    }
}