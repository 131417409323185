
		  @import "src/styles-theme/variables.scss";
		  


.profile-employment-item-container {
  .activity-gap-container {
    display: flex;
    justify-content: flex-end;
    margin-right: $size-40;
    .activity-gap-tag {
      background-color: $color-red-font;
      font-size: 11px;
      width: 67px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      font-weight: $font-demi_bold;
      border-radius: 4px;
      margin: $size-8 0;
      color: $color-white;
    }

  }
}
