
		  @import "src/styles-theme/variables.scss";
		  


.alert-component {
    width: 300px;
    position: fixed;
    top: $size-8;
    left: $size-8;
    z-index: 10000000;
    border-radius: 4px;
    background-color: $color-grey-6;
    padding: $size-16;
    min-height: $size-80;
    box-shadow: $shadow_3;

    .alert-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $size-8;
        img {
            
            &.error-img {
                transform: translateY(6px);
                width: $size-32;
                height: $size-32;
            }
            &.info-img {
                transform: translateY(6px);
                width: $size-24;
                height: $size-24;
            }
        }
    }

    p {
        font-size: 14px;
        font-weight: $font_semi_bold;
        color: $color-grey-1;
    }

}
