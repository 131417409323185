
		  @import "src/styles-theme/variables.scss";
		  


.profile-employment-top {
    margin: 0 $size-32 4px $size-104;
    background-color: $color-grey-8;

    @media only screen and (max-width: 700px) {
        margin: $size-8;
    }
    .profile-employment-top-section {
        display: flex;
        justify-content: space-between;
        padding: $size-24;
        position: relative;

        .section-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: $size-80;
            .section-type {
                font-size: 14px;
                color: $primary-color;
                text-decoration: underline;
                text-underline-position: under;
                font-weight: $font_semi_bold;
                cursor: pointer;
            }
            .section-name-container {
                display: flex;
                align-items: center;
                img {
                    margin-left: $size-8;
                }
                .section-name {
                    font-size: 12px;
                    font-weight: $font_semi_bold;
                    color: $color-black;
                }
            }

            
            .section-files {
                display: flex;
                align-items: center;
                margin-top: $size-8;
                p {
                    color: $primary-color;
                    font-size: 12px;
                    font-weight: $font_semi_bold;
                }
                img {
                    width: $size-16;
                    height: auto;
                    margin-right: $size-8;
                    margin-bottom: 2px;
                }
                .reference-status {
                    padding: 2px 4px;
                    background-color: $color-white;
                    margin-left: $size-16;
                    p {
                        font-size: 10px;
                        color: $color-black;
                    }
                }
            }
        }

        .section-right {
            .section-dates {
                font-size: 14px;
                 font-weight: $font_demi_bold;
                color: $color-grey-1;
                &.verified {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: $font_semi_bold;
                }
            }
            .section-edited {
                font-size: 12px;
                // font-weight: $font_semi_bold;
                color: $color-grey-3;
            }
        }
        
       
        .overlap {
            p {
                color: $primary-color;
                font-weight: $font_demi_bold;
                font-size: 11px;
            }
            background-color: $color-white;
            height: $size-24;
            width: $size-64;
            text-align: center;
            border-radius: 2px;
            position: absolute;
            right: $size-24;
            transform: translateY(-40px);
        }

       
        
    }
}
