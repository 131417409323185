
		  @import "src/styles-theme/variables.scss";
		  
.external-settings-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
	.external-settings-sections {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}
}