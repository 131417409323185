
		  @import "src/styles-theme/variables.scss";
		  


.phone-input {
    input {
        width: 45px;
        background-color: $color-white;
        height: $size-48;
        outline: none;
        border: none;
        border-radius: 5px;
        margin-right: $size-8;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        text-align: center;
        
    }
}
