
		  @import "src/styles-theme/variables.scss";
		  


.stage-container {
    display: flex;
    align-items: center;
    margin: $size-16 $size-32 4px $size-32;

    @media only screen and (max-width: 700px) {
        margin: $size-16 $size-8 4px $size-8;
    }

}