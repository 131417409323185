
		  @import "src/styles-theme/variables.scss";
		  


.share-code-section {
      padding: $size-24;
      background-color: $color-grey-8;
    .share-code-edit-section {
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;
      &.share-calendar {
        margin-bottom: $size-16;
        width: fit-content;
        img {
          margin-left: -27px;
        }
      }
        img {
          margin-left: $size-8;
          margin-top: 2px;
          cursor: pointer;
        }
    }
 }
