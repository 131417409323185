
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-tag-section {
    .dashboard-tag-section-container {
        display: flex;
        justify-content: center;
        .dashboard-tag-item {
            background-color: $color-grey-8;
            width: fit-content;
            border-radius: 5px;
            padding: 4px $size-16;
            min-height: $size-24;
            margin-right: $size-8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                color: $color-black;
                font-size: 10px;
                font-weight: $font_semi_bold;
            }
            &.urgent {
                background-color: $primary-color;
                p {
                    color: $color-white;
                }
            }
          
        }
        .extra-tags {
            background-color: $color-grey-8;
            border-radius: 5px;
            padding: 4px $size-8;
            min-height: $size-24;
            cursor: help;
            p {
                color: $color-black;
                font-size: 10px;
                font-weight: $font_semi_bold;
            }
        }

    }
    
}
