
		  @import "src/styles-theme/variables.scss";
		  
.external-profile-sidebar-section-title-container {
    margin-bottom: 0.75rem;

    .external-profile-sidebar-section-header {
		align-items: center;
		color: var(--color-chateau);
        display: flex;
        gap: 0.5rem;
        margin-bottom: 0.25rem;
        
        .external-profile-sidebar-section-title {
			color: #9AA5B1;
			font-size: 14px;
			font-weight: 500;
			margin: 0;
            position: relative;
            top: 2px;
        }

		.grey-info-icon {
			cursor: pointer;
			margin-top: 9px;
		}
    }
    .external-profile-sidebar-section-body {
		display: flex;
        font-size: 16px;
		word-wrap: break-word;

		.external-profile-sidebar-section-clearance-container {
			align-items: center;
			display: flex;
			margin-bottom: 8px;

			.external-profile-sidebar-section-clearance-icon {
				margin-right: 8px;
			}
	
			.clearance-status-text {
				font-size: 14px;
				font-weight: 500;
			}

			.green-info-icon {
				cursor: pointer;
				margin-left: 8px;
			}
		}

		.clearance-date-margin {
			margin-top: 5px;
		}

		.no-icon-margin {
			margin-bottom: 8px;
			margin-top: 5px;
		}
    }
}
