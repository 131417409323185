
		  @import "src/styles-theme/variables.scss";
		  


.organisations-header {
	margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    height: $size-120;
    @media only screen and (max-width: 600px)  {
        flex-direction: column;
    }
    //@media only screen and (max-width: 400px)  {
    //    height: 160px;
    //}

 
    .organisations-header-title {
        height: 120px;
        display: flex;
        align-items: center;
        padding-left: $size-24;
        .organisations-header-back-btn {
            cursor: pointer;
            margin-right: $size-16;
            margin-bottom: 2px;
        }
        h2 {
            color: $primary-color;
        }
        @media only screen and (max-width: 400px)  {
            display: none;
        }
    }

    .organisations-header-actions {
        height: $size-120;
        display: flex;
        align-items: center;
        .orka-button {
            margin-right: $size-16!important;
        }
        @media only screen and (max-width: 700px) {

            justify-content: center;
            align-items: center;
        }
        @media only screen and (max-width: 400px)  {
            flex-direction: column;
            button {
                margin-bottom: 16px;
            }
        }
       
        .create-organisation {
            cursor: pointer;
            button {
                color: $primary-color;
                font-weight: $font_semi_bold;
                text-decoration: underline;
                text-underline-position: under;
                font-size: 14px;
                background-color: transparent;
                min-width: 220px;
                border: none;
                outline: none;
                cursor: pointer;
                
            }
        }
        .organisation-search {
            margin-right: $size-24;
            @media only screen and (max-width: 700px) {
                margin-right: unset;
            }

            .search-component {
                width: 180px;
            }
        }
  
    }

    
}
