
		  @import "src/styles-theme/variables.scss";
		  


.activity-details-section {
    padding: $size-32 0;
    .activity-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $size-32;
        img {
            cursor: pointer;
        }
      
    }
   
    .create-activity {
        width: calc(100% - 64px);
        margin: auto;
        display: flex;
        justify-content: flex-end;
    }

    .reference-notes {  
        margin: $size-8 0 $size-16 0;
        textarea {
            border: 1px solid $color-grey-5;
            outline: none;
            width: 94%;
            border-radius: 4px;
            height: $size-120;
            color: $color-black;
            padding: $size-16;
        }
    }

    
}
