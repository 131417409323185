
		  @import "src/styles-theme/variables.scss";
		  


.dashboard-row-assigned-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  .last-chased-container {
    margin-top: 2px;
    p {
      font-size: 12px;
      font-weight: $font_semi_bold;
      color: $color-grey-3;
    }
  }
  .open-assigned {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  .row-assigned-section {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .extra-assigned {
      color: $color-grey-3;
      font-weight: $font_demi_bold;
      font-size: 12px;
    }
    .row-assigned-circle {
      margin-right: $size-8;
      border: 1px solid $color-grey-5;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-weight: $font_demi_bold;
        font-size: 10px;
      }
    }
  }

  .color-green {
    color: #1DC7B2;
  }
  .color-blue {
    color: #485BEB;
  }
  .color-orange {
    color: #FF7B00;
  }
  .color-purple {
    color: #9013FE;
  }
  .color-red {
    color: #EC8079;
  }
}
