
		  @import "src/styles-theme/variables.scss";
		  


.rtw-expiry-container {
    margin-left: $size-72-5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-grey-8;
    margin-bottom: 4px;
    padding: 0 $size-24 0 $size-32;
    height: $size-80;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }

    img {
        cursor: pointer;
        width: 14px;
        
    }
}