
		  @import "src/styles-theme/variables.scss";
		  
.dropdown-area-container {
	.dropdown-red {
	}
	.dropdown-green {
		background-color: $color-green;
		p {
			color: $color-green-font;
		}
	}
	.dropdown-yellow {
		background-color: $color-yellow;
		p {
			color: $color-yellow-font;
		}
	}
	.blue-outline {
		border: 1px solid $primary-color;
	}
	.dropdown-grey {
		background-color: $color-grey-8;
		color: $color-grey-1;
		&.light {
			background-color: darken($color-grey-8, 5%);
			color: $color-grey-1;
		}
	}
	.dropdown-dark {
		background-color: $color-grey-5;
		color: $color-grey-1;
		&.light {
			background-color: darken($color-grey-5, 5%);
			color: $color-grey-1;
		}
	}
	.dropdown-top {
		.dropdown-header-text {
			font-size: 12px;
			font-weight: $font_semi_bold;
		}
		position: relative;
		display: inline-block;
		cursor: pointer;
		&.mobile {
			@media only screen and (max-width: 900px) {
				width: 12px !important;
				margin-right: $size-16;
			}
		}
		&.action-list {
			width: 24px !important;
			margin-left: 12px;
		}
		&.arrow-only {
			width: 12px !important;
			margin-right: $size-16;
		}

		.action {
			width: 28px;
			height: auto;
			margin-top: 6px;
			transform: translateX(-12px);
		}
		.dropdown-top-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: $font_semi_bold;
			height: $size-32;
			padding: 6px 12px;
			border-radius: 4px;
			font-size: 14px;
			&.mobile {
				@media only screen and (max-width: 900px) {
					width: 12px !important;
				}
			}
			&.action-list {
				width: 12px;
				border: 1px solid red;
			}
			&.arrow-only {
				width: 12px !important;
			}

			.drop-top-img {
				display: flex;
				align-items: center;
				img {
					width: $size-16;
					height: $size-16;
					margin-right: $size-8;
				}
			}
			@media only screen and (max-width: 900px) {
				.mobile {
					display: none;
				}
			}
			img {
				width: 12px;
				height: 12px;
			}
		}
	}
	.dropdown-area {
		display: block;
		position: absolute;
		clear: both;

		background-color: white;
		z-index: 10000;
		box-shadow: $shadow_5;
		border-radius: $size-8;
		padding: $size-16 0;
		transform: translateY(10px);
		width: 192px;
		max-height: 340px;
		.dropdown-scrollable-area {
			max-height: 340px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 50px;
				background: $color-grey-2;
			}

			&::-webkit-scrollbar {
				display: none;
			}

			&:hover::-webkit-scrollbar {
				display: block;
			}
		}

		&.bottom-center {
			left: 0;
		}

		&.bottom-right {
			right: 0;
		}
		&.bottom-left {
			left: 0;
		}
		&.top-center {
			left: 0;
		}

		&.top-right {
			right: 0;
		}
		&.top-left {
			left: 0;
		}
		.static {
			left: 0;
		}
		@media only screen and (max-width: 900px) {
			&.mobile {
				transform: translateX(20px) translateY(12px);
			}
		}

		.triangle {
			background-color: $color-white;
			clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
			width: 12px;
			height: 12px;
			position: absolute;
			left: 0;
			top: 0;

			&.triangle-reversed {
				top: unset;
				bottom: 0;
				transform: rotate(180deg);
			}
		}

		.dropdown-item-list {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
		}

		.dropdown-item-small{
			padding-top: $size-8 !important;
			padding-bottom: $size-8 !important;
			p{
				font-size: 12px !important;
			}
			.add-staff-avatar{
				width: 36px !important;
				height: 36px !important;
				margin-right: $size-24 !important;
			}
		}
		.dropdown-item {
			padding: $size-16;

			p {
				font-size: 14px;
				&.selected {
					color: $primary-color;
					font-weight: $font_semi_bold;
				}
			}
			.arrow {
				width: 16px;
				height: 16px;
			}
			&:hover {
				background-color: $primary-color;
				p {
					color: $color-white;
				}
				.add-staff-avatar {
					background-color: $color-grey-3 !important;
				}
			}
			&.disabled {
				cursor: not-allowed;
				p {
					color: $color-grey-4;
				}
				&:hover {
					p {
						color: $color-grey-4;
					}
				}
			}
		}
	}
}
