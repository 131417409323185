
		  @import "src/styles-theme/variables.scss";
		  


.user-info {
    margin-bottom: $size-8;
    p {
        text-align: center;   
        font-weight: $font_semi_bold;
        margin-bottom: $size-8;
    
    }

}
.sign-up-action-link {
    display: flex;
    justify-content: center;
    p {
        color: $primary-color;
        cursor: pointer;
    }
}



