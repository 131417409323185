
		  @import "src/styles-theme/variables.scss";
		  


    .organisations-sub-header-container {
        background-color: $color-white;
        height: $size-80;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        padding-left: $size-24;
        overflow-x: scroll;
        overflow-y: hidden;
       @media only screen and (max-width: 700px) {
           padding-left: 4px;
       }
        &::-webkit-scrollbar {
            width: 0px;
        }
        .organisations-sub-header-container-left {
            align-items: center;
            height: inherit;
            display: flex;
            flex-grow: 1;
            p {
                font-size: 14px;
                color: $color-grey-3;
            }
            .center {
                display: flex;
                justify-content: center;
            }
            .col-sm {
                min-width: 96px;
                max-width: 96px;
            }
            .col-md {
                min-width: 120px;
                max-width: 120px;
                padding-left: $size-16;
                margin-right: $size-24;
            }
            .col-lg {
                min-width: 300px;
                max-width: 300px;
                margin-left: $size-32;
            }
            .days {
                margin-right: 4px;
            }
            @media only screen and (max-width: 700px) {
                .col-sm {
                    min-width: 72px;
                    max-width: 72px;
                }
               
                .col-md {
                    min-width: 80px;
                    max-width: 80px;
                    padding-left: 4px;
                }
                .col-lg {
                    min-width: 120px;
                    max-width: 120px;
                }
             
                .accepted-shifts {
                    min-width: 60px;
                    max-width: 60px;
                }
            
                
            }
        }
        .organisations-sub-header-container-right {
            min-width: 240px;
            max-width: 240px;
            @media only screen and (max-width: 700px) {
                min-width: 40px;
                max-width: 40px;
            }
            
        }
    }