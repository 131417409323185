
		  @import "src/styles-theme/variables.scss";
		  


.add-activity-btn {
    
    display: flex;
    background-color: $color_grey_8;
    padding: $size-16 $size-32;
    margin: 4px $size-32 4px $size-104;
    justify-content: space-between;
    @media only screen and (max-width: 700px) {
        margin: $size-8;
    }
    
    button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        color: $primary-color;
        text-decoration: underline;
        text-underline-position: under;
        font-size: 14px;
        font-weight: $font_semi_bold;
        

    }
    img {
        cursor: pointer;
    }
  
}