
		  @import "src/styles-theme/variables.scss";
		  
.dashboard-notes-container {
    display: flex;
    justify-content: center;
    button {
        background-color: transparent;
        border: none;
    }
    .edit-notes {
        border: 1px solid $primary-color;
        height: $size-32;
        min-width: $size-32;
        max-width: $size-32;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
    }
}
