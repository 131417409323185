
		  @import "src/styles-theme/variables.scss";
		  


.table-header {
    display: flex;
    background-color: $color-white;
    height: $size-80;
    align-items: center;
    margin-bottom: 4px;
    min-width: max-content;
    .col {
        display: flex;
        align-items: center;

    }
    .col-sm {
        // flex: 0.5;
        min-width: 120px;
        max-width: 120px;
        justify-content: center;
    }

    .col-md {
        // flex: 1;
        min-width: 130px;
    	max-width: 130px;
        justify-content: center;
    }

    .col-lg {
        // flex: 2;
        min-width: 240px;
        max-width: 240px;
    }

    .center {
        display: flex;
        justify-content: center;
    }

    p {
        color: $color-grey-3;
        font-size: 14px;
    }
}
