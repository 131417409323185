
		  @import "src/styles-theme/variables.scss";
		  


.loader {
    
    display: flex;
    margin: auto;
    width: inherit;
    height: inherit;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    .loading-anim {
        img {
            width: 80px;
            -webkit-animation: scaleLogo 1.5s linear infinite; /* Safari */
            animation: scaleLogo 1.5s linear infinite;
        }
    }
    /* Safari */
    @-webkit-keyframes scaleLogo {
        0% {
            transform: scale(0.9);
        }
        20% {
            transform: scale(1.1);
            opacity: 0.6;
        }
        100% {
            transform: scale(0.9);
            opacity: 1;
        }
    }
    @keyframes scaleLogo {
        0% {
            transform: scale(0.9);
        }
        20% {
            transform: scale(1.1);
            opacity: 0.6;
        }
        100% {
            transform: scale(0.9);
            opacity: 1;
        }
    }

}