
		  @import "src/styles-theme/variables.scss";
		  


.organisations-row-container {
    display: flex;
    background-color: $color-white;
    height: $size-80;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    min-width: fit-content;
    padding-left: $size-24;
    @media only screen and (max-width: 700px) {
        padding-left: 4px;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
    .organisations-row-container-left {
        height: $size-80;
        align-items: center;
        display: flex;
        flex-grow: 1;
        p {
            font-size: 14px;
            font-weight: $font_semi_bold;
        }
        .center {
            display: flex;
            justify-content: center;
        }
        .col-sm {
            min-width: 96px;
            max-width: 96px;
        }
        .col-md {
            min-width: 120px;
            max-width: 120px;
            padding-left: $size-16;
            margin-right: $size-24;
        }
        .col-lg {
            min-width: 300px;
            max-width: 300px;
            margin-left: $size-32;
        }
        .days {
            margin-right: 4px;
        }
        @media only screen and (max-width: 700px) {
            .col-sm {
                min-width: 72px;
                max-width: 72px;
            }

            .col-md {
                min-width: 80px;
                max-width: 80px;
                padding-left: 4px;

            }
            .col-lg {
                min-width: 120px;
                max-width: 120px;
            }


        }
    }
    .organisations-row-container-right {

        height: $size-80;
        @media only screen and (max-width: 700px) {
            min-width: 40px;
            max-width: 40px;
        }

    }
}