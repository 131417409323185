
		  @import "src/styles-theme/variables.scss";
		  


.breakdowns {
    display: flex;
    // justify-content: space-between;
    background-color: $color-grey-8;
    padding: $size-24 $size-8;
    .breakdown-side {
        margin-left: $size-16;
    }
    @media only screen and (max-width: 720px) {
        flex-direction: column;     
        .breakdown-side {
         
            text-align: center;
         
            

        }
    }
}