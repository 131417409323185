
		  @import "src/styles-theme/variables.scss";
		  
.role-permission-card {
	background-color: $color-grey-7;
	padding: 15px;

	.role-permission-header-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5px;
	}
}

.role-permission-title {
	font-weight: 600;
	font-size: $size-14;
	color: $color-grey-1;
}

.role-permission-description {
	font-weight: 400;
	font-size: $size-16;
	font-family: inherit;
	color: $color-grey-3;
}