
		  @import "src/styles-theme/variables.scss";
		  
.notes-modal-container {
    p {
        line-height: 16px;
    }

    textarea {
        border: 1px solid #d0d8e0;
        border-radius: 5px;
        width: calc(100% - 32px);
        height: $size-160;
        margin-top: $size-32;
        outline: none;
        padding: 16px !important;
    }

    .notes-modal-btn {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: $size-32;
        right: $size-32;
    }
}
