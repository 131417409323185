
		  @import "src/styles-theme/variables.scss";
		  


.profile-secondary-container {
    background-color: $color-white;
    margin-left: $size-16;
    margin-top: $size-16;
    border-radius: 4px;
	position: relative;
    height: 80vh;
    min-width: 240px;

    @media only screen and (max-width: 900px) {
        margin-top: $size-40;
        margin-left: 0;
    }
    &::-webkit-scrollbar {
        width: 0;
    }
  .profile-secondary-container-inner {
    overflow-y: scroll;
    overflow-x: scroll;
    height: calc(100% - 48px);
    padding: $size-24 $size-32 0 $size-32;
    &::-webkit-scrollbar {
		width: 8px;
		height: 0px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 50px;
		background: $color-grey-2;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&:hover::-webkit-scrollbar {
		display: block;
	}
    .secondary-container-item {
      margin-bottom: $size-8;
    }

    .submission-details {
      padding: $size-16 0 $size-8 0;
      margin: $size-16 0;
      border-top: 1px solid $color-grey-8;
      .submission-status {
        margin-bottom: $size-32;
      }
    }

    .final-status {
      border-top: 1px solid $color-grey-8;
      padding-top: $size-16;
    }
  }
    


	.secondary-container-selection {
		position: absolute;
		top: -32px;
		margin-top: 16px;
		right: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-radius: 4px;
		button {
			width: 50%;
			height: $size-32;
			background-color: $color-white;
			border: none;
			border-top-right-radius: 39px;
			border-top-left-radius: 8px;
			cursor: pointer;
			color: $color-grey-4;
			font-weight: 500;
			font-size: $button_font_size;
		}
		.selected {
			color: $primary-color;
		}
	}




    
}

