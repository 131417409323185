
		  @import "src/styles-theme/variables.scss";
		  


.profile-doc-container {
    background-color: $color_white;
 
    .loading-file-container {
        width: 100%;
        position: relative; 
    }
}
