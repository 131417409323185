
		  @import "src/styles-theme/variables.scss";
		  


.activity-verified-container {
    margin-bottom: $size-32;
    border-radius: 6px;
    .organisation-info-container {
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        border-radius: 6px;
    }
    .activity-verified-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $size-16;
        background-color: $color-grey-8;
        border-radius: 6px;

        .activity-verified-top-left {
            display: flex;
            align-items: center;
            p {
                font-size: 12px;
                font-weight: $font_semi_bold;

            }
            img {
                margin-right: $size-8;
            }
        }
        .activity-verified-top-right {
            button {
                cursor: pointer;
                text-decoration: underline;
                text-underline-position: under;
                color: $primary-color;
                font-size: 14px;
                line-height: $size-16;
                border: none;
                outline: none;
            }
        }

        .verified {
            color: $color-green-font;
        }
        .not-verified {
            color: $color-red-font;
        }
    }
    .activity-verified-actions {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-8;
        margin-top: -$size-8;
        height: $size-40;


        .activity-verified-action {
            display: flex;
            width: 50%;
            justify-content: center;
            align-items: center;
            background-color: $color-grey-8;
            border: 1px solid $color-grey-4;
            height: 100%;
            cursor: pointer;
            font-size: 14px;
            color: $primary-color;
            font-weight: $font_demi_bold;

            &:first-of-type {
                border-radius: 0 0 0 6px;
                border-right: none;
            }
            &:last-of-type {
                border-radius: 0 0 6px 0;

            }

        }
    }

}


