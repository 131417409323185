
		  @import "src/styles-theme/variables.scss";
		  


.organisation-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-right: $size-24;
    @media only screen and (max-width: 700px) {
        margin: 2px;
    }

}

.show-organisation-info-button {
    border: none;
    outline: none;
    color: $primary-color;
    cursor: pointer;
    margin-bottom: $size-16;
    background-color: transparent;
}
.orka-button button {
    height: 34px;
    min-width: $size-120;
}
.edit {
    border: 1px solid $primary-color;
    height: $size-32;
    min-width: $size-32;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-right: $size-8;
    cursor: pointer;
    background-color: transparent;
    margin-bottom: 4px;
        margin-left: 2px;
    @media only screen and (max-width: 700px) {
        height: $size-24;
        width: $size-24;
        border: none;
    }
 
}
