
		  @import "src/styles-theme/variables.scss";
		  


.profile-subsection-container {
    margin-left: $size-72-5;
    margin-bottom: 4px;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
    .subsection-dropdown-top {
        background-color: $color-grey-6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 0 $size-32;
        height: $size-40;
        margin-top: 4px;
        cursor: pointer;
        img {
            height: 6px;

        }
        p {
            font-size: 12px;

        }
        
    }
    .subsection-children {
        margin-top: 4px;
    }
}
