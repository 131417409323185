
		  @import "src/styles-theme/variables.scss";
		  


.organisation-create-top {
    margin-bottom: $size-32;
    .edit-input-area {
        margin-bottom: $size-16;
    }
}

.organisation-create-bottom {
    .edit-input-area {
        margin-bottom: $size-16;
    }
    .organisation-create-flex {
        display: flex;
        justify-content: space-between;
        .organisation-create-item {
            width: calc(50% - 8px);
        }
    }
}

.company-exists-container {
    background-color: $color-grey-8;
    padding: $size-16;
    margin-bottom: 4px;
    &.pre-populated {
        cursor: pointer;
    }
    .name {
        font-weight: $font_demi_bold;
    }
    .city {
        margin-right: 4px;
    }
    p {
        font-size: 14px;
        color: $color-black;
    }
    .company-exists-info {
        display: flex;
        align-items: center;
        
        p {
            font-size: 12px;
        }
        img {
            margin-right: $size-8;
        }
        
        margin-bottom: $size-8;
    }
}



.modal-btn-section {
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: $size-40;
}