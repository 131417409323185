
		  @import "src/styles-theme/variables.scss";
		  


.certificate-footer {
    margin-top: $size-40;
    
    p {
        font-size: $size-8;
        line-height: 12px;
        text-align: center;
    }
    .footer-images {
        display: flex;
        justify-content: space-between;
        margin-top: $size-16;
        img {
            width: $size-72;
        }
        p {
            font-size: $size-8;
        }
    }
}