
		  @import "src/styles-theme/variables.scss";
		  


.cert-section-title {
    background-color:$color-grey-8;
    margin-bottom: $size-8;
    padding-left: $size-16;
    
    p {
        font-size: 10px;
        color: $color-grey-3;
        font-weight: $font_semi_bold;

    }
}

.cert-history-container {
    position: relative;
    padding-right: $size-64;
    padding-left: $size-24;
    
    

    p {
        font-size: 10px;
        color: $color-grey-1;
    }
    
    .grey-text {
        color: $color-grey-3;

    }
    .cert-history-header {
        display: flex;
        border-bottom: 1px solid $color-grey-8;
        min-height: $size-32;
      
        .col {
            flex: 1;
            margin-right: $size-8;
        }
        .col-lg {
            flex: 1.4;
            margin-right: $size-8;
        }
    }
    .cert-history-row {
        display: flex;
        border-bottom: 1px solid $color-grey-8;
        align-items: center;
        min-height: $size-40;
        position: relative;
        p {
            font-weight: $font_semi_bold;
            color: $color-black;
            font-size: 10px;
        }
        &.extra-padding {
            padding-top: 12px;
        }
        .overlap {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $color-grey-8;
            padding: 2px $size-16;
            color: $color-grey-3;
            font-size: 9px;
            width: $size-72;

        }
        .col {
            flex: 1;
            margin-right: $size-8;
            
        }
        .col-lg {
            flex: 1.4;
            margin-right: $size-8;
        }
    }
}