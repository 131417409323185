
		  @import "src/styles-theme/variables.scss";
		  


.mobile-header {
    display: flex;
    justify-content: space-between;
    background-color: $color-white;
    height: $size-80;
    border-bottom: 1px solid $color-grey-6;
    align-items: center;
    // padding: 0 $size-24;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    @media only screen and (min-width: 700px) {
        display: none;
    }
    img {
        cursor: pointer;
    }
    .avatar {
        background-color: $primary-color;
        color: $color-white;
        width: $size-40;
        height: $size-40;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $size-24;
    }

    .hamburger {
        margin-left: $size-24;
        cursor: pointer;
    }
}
