
		  @import "src/styles-theme/variables.scss";
		  


.not-found {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 100px;
        height: auto;
        margin: $size-16;
    }
    p {
        font-size: 12px;
        font-weight: $font_semi_bold;
        color: $color-grey-3;
    }
}