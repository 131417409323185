
		  @import "src/styles-theme/variables.scss";
		  
.sign-up-confirm-container {
    height: 50vh;
	.verification-info-text {
		color: $color-grey-1;
		font-size: 14px;
		font-weight: $font-demi-bold;
		line-height: 22px;
		text-align: center;
	}
    .phone-code {
        margin: $size-32 0 $size-40 0;
        display: flex;
		justify-content: center;
    }
    .sign-up-action-link {
        margin-top: $size-24;
        cursor: pointer;
        p {
            color: $primary-color;
            text-align: center;
        }
    }
}