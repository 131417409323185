
		  @import "src/styles-theme/variables.scss";
		  


// NAMING AREAS FOR GRID

// .profile-status-header {
//     grid-area: status-bar;
//   }
//   .profile-header {
//     grid-area: header;
//   }
//   .profile-nav {
//     grid-area: nav;
//   }
  .profile-primary-container {
    grid-area: primary-container;
  }
  .profile-secondary-container {
    grid-area: secondary-container;
  }
  .profile-header {
    grid-area: profile-header;
  }
//   .profile-notes {
//     grid-area: notes;
//   }


.profile-container {
    flex-grow: 1;
    height: 95vh;
   
    // overflow-y: scroll;
    // overflow-x: visible;
    display: grid;
    transition: 0.3s linear;
    @media only screen and (max-width: 720px) {
        margin-left: $size-16;
    }

    @media only screen and (max-width: 520px) {
        margin-left: 0;
    }
    // PROFILE LAYOUTS FOR SCREEN SIZES
    @media only screen and (max-width: 899px) {
        grid-template-columns: 100%;
        height: unset;
        grid-template-areas:
        
        "profile-header"
        "primary-container"
        "secondary-container"
     
        
    }   
    
    @media only screen and (min-width: 900px) {
        grid-template-columns: 6fr 3fr;
        grid-template-areas:
        "profile-header profile-header"
        "primary-container secondary-container"
    
    }   
}

