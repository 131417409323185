
		  @import "src/styles-theme/variables.scss";
		  
.file-owner-search-container {
	padding: $size-8;
	position: absolute;
	background-color: white;
	left: -16px;
	width: calc(100% + 16px);
	z-index: 10000;
	box-shadow: $shadow_3;
	border-radius: 5px;

	.file-owner-search-component {
		background-color: $color-grey-8;
		padding: 4px 12px;
		display: flex;

		align-items: center;
		border-radius: 4px;
		margin-bottom: $size-16;

		img {
			margin-right: $size-8;
		}

		input {
			height: 100%;

			font-size: 12px;
			height: $size-32;
			font-weight: $font_semi_bold;
			background-color: $color-grey-8;
			color: $color-grey-1;
			border: none;
			outline: none;
			&::placeholder {
				color: $color-grey-3;
			}
		}
	}

	.file-owner-search-container-list {
		max-height: 220px;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 0px;
		}

		.file-owner-search-container-list-item {
			padding: $size-8;
			cursor: pointer;

			p {
				font-weight: 400;
				font-size: 12px;
			}

			&:hover {
				p {
					color: $primary-color;
				}
			}
		}
	}
}
