
		  @import "src/styles-theme/variables.scss";
		  

.stage-title {
    font-weight: $font_semi_bold;
    font-size: 16px;
}

.risk-check-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: $size-24;

    .risk-check {
        display: flex;
        margin-right: $size-48;
        margin-bottom: $size-16;
        p {
            font-size: 14px;
        }
        img {
            margin-right: $size-16;
            cursor: pointer;
        }
    }
}

.risk-description {  
    margin: $size-8 0 $size-16 0;
    textarea {
        border: 1px solid $color-grey-5;
        outline: none;
        width: 94%;
        border-radius: 4px;
        height: $size-120;
        color: $color-black;
        padding: $size-16;
    }
}

.risk-modal-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .risk-modal-assigned-dropdown {
        margin-bottom: 22px;
    }
}
