
		  @import "src/styles-theme/variables.scss";
		  


.external-search-component {
    background-color: $color-grey-8;
    padding: 6px 12px;
    display: flex;
  
    align-items: center;
    border-radius: 4px;
    width: $size-240;
    @media only screen and (max-width: 500px) {
        width: 200px;
    }
    img {
        margin-right: $size-8;
    }
  
    input {
        height: 100%;
        width: 100%;
        font-size: 14px;
        height: $size-32;
        font-weight: $font_semi_bold;
        background-color: $color-grey-8;
        color: $color-grey-3;
        border: none;
        outline: none;
        &::placeholder {
            color: $color-grey-3;
 
        }
    }
}
