
		  @import "src/styles-theme/variables.scss";
		  
.add-candidate-modal-container {
	height: 100%;
	.add-candidate-input-row {
		display: flex;
		align-items: flex-start;
		margin: $size-24 0;
		justify-content: space-between;
		.add-candidate-col-left,
		.add-candidate-col-right {
			width: calc(50% - 16px);
		}
		.add-candidate-col-left {
			margin-right: $size-16;
		}
	}
	.start-date-input {
		display: flex;
		align-items: flex-start;
		width: fit-content;
		img {
			margin-left: $size-16;
			margin-top: 2px;
			cursor: help;
		}
	}
	.add-candidate-btn-section {
		display: flex;
		justify-content: flex-end;
		margin-top: $size-72;
	}
}
