
		  @import "src/styles-theme/variables.scss";
		  
.external-settings-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.external-settings-header-left {
	border-bottom: 3px solid $primary-color;
	padding: 8px 5px;
	.external-settings-header-title {
		margin: 0;
	}
}
.external-settings-header-right {
	border-bottom: 3px solid $color-grey-5;
	padding: 8px 10px;
	flex-grow: 2; 
	.external-settings-add-button {
		background-color: $primary-color;
		color: $color-white;
		padding: 8px 16px;
		border: none;
		border-radius: 5px;
		float: right;
		cursor: pointer;
	}
	.external-settings-add-button:hover {
		background-color: $color-primary-lighter;
	}
}