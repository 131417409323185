
		  @import "src/styles-theme/variables.scss";
		  


@media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  

  
  @page {
    size: auto;
    margin: 20mm;
  }


.declaration-content {
    overflow-y: scroll;
    
    @media print {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
        background-color: white;
  
    }

    .print-heading {
       
        h1 {
            opacity: 0;
            margin-top: -$size-32;
        }
        @media print {
           
            h1 {
                font-size: $size-40;
                opacity: 1;
                margin-top: 0;
            }
            display: block;
            margin-bottom: $size-40;
        }
    }
  
}

.declaration-modal-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.declaration-loading-spinner {
  
  height: 480px;
  width: 100%;
  position: relative;
 
}