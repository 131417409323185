
		  @import "src/styles-theme/variables.scss";
		  
.profile-document-details {
	margin-left: $size-72-5;
	padding: $size-24;
	background-color: $color-grey-8;
	@media only screen and (max-width: 700px) {
		margin-left: unset;
	}
	.checkbox-area{
		display: flex;
		align-items: center;
		margin-top: $size-8;
		img{
			margin-right: $size-8;
			cursor: pointer;
		}
	}
	.checkbox-disabled{
		opacity: 0.3;
		cursor: default !important;
	}
}
