
		  @import "src/styles-theme/variables.scss";
		  


.risk-modal-assigned {
    .risk-item-flexed {
        display: flex;
        align-items: center;
        width: calc(100% - 80px);
        .risk-col {
          flex: 1;
        }
    }
    .risk-reason {
        font-size: 14px;
        font-weight: $font_semi_bold;
    }
    .risk-reason-original {
        margin-top: $size-8;
        font-size: 14px;
        color: $color-grey-1;
        font-style: italic!important;
    }
    textarea {
      border: 1px solid $color-grey-4;
      outline: none;
      margin: $size-8 0;
      width: calc(100% - 60px);
      border-radius: 4px;
      height: $size-96;
      color: $color-black;
      padding: $size-8 12px;
    }

    .controller-input {
        margin-bottom: $size-40;
        input {
          border: none;
          border-bottom: 1px solid $primary-color;
          width: calc(100% - 32px);
          outline: none;
          font-size: $size-16;
          font-weight: $font_demi_bold;
          margin-top: $size-8;
          padding-bottom: 4px;

        }
    }

    .action-risk-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: calc(100% - 32px);
        div:nth-child(1) > button {
          background-color: $color-white;
          margin-right: $size-16;
          border: 1px solid $primary-color;
          color: $primary-color;
        }

    }

}
