
		  @import "src/styles-theme/variables.scss";
		  



.floating-footer {
 
    margin-left: $size-64;
    margin-right: $size-64;
    height: $size-80;
    position: fixed;
    background-color: $color-white;
    bottom: $size-24;
    left: 20%;
    right: 20%;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.08);
    @media only screen and (max-width: 900px) {
        left: 0;
        right: 0;
    }
    
    .floating-footer-header {
        
        img {
            position: absolute;
            top: $size-8;
            right: $size-8;
            cursor: pointer;
        }
    }
    .floating-footer-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .floating-footer-item-list {
            display: flex;
            margin-left: $size-16;
            .floating-footer-item {
                width: $size-16;
                height: $size-16;
                border-radius: 50%;
                background-color: $primary-color;
                margin: 0 4px;
            }
        }
        .floating-footer-actions {
            display: flex;
            button {
                color: $primary-color;
                border: 1px solid $primary-color;
                background-color: $color-white;
                height: $size-40;
                border-radius: 10px;
                font-weight: $font_semi_bold;
                padding: 0 $size-8;
                margin-right: $size-32;
                cursor: pointer;

            }

            .footer-actions {
                display: flex;
                margin-right: $size-24;
                .footer-action {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 $size-16;
                    cursor: pointer;
                    p {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}