
		  @import "src/styles-theme/variables.scss";
		  
//FONTS
@font-face {
  font-family: "AvenirNext";
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/AvenirNext-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-style: normal;
  font-weight: 800;
  src: url("../src/assets/fonts/AvenirNext-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-style: normal;
  font-weight: 900;
  src: url("../src/assets/fonts/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-style: demi-bold;
  font-weight: 600;
  src: url("../src/assets/fonts/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-style: medium;
  font-weight: 500;
  src: url("../src/assets/fonts/AvenirNext-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-style: light;
  font-weight: 200;
  src: url("../src/assets/fonts/AvenirNext-UltraLight.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
}

body,
html {
  margin: 0 auto;
  background-color: #f7f7f7;
  scroll-behavior: smooth;
}

body {
  font-family: "AvenirNext" !important;
  //   background-color: $color_grey_7 !important;
}
@import "./styles-theme/theme.scss";

// Any class of back-btn should behave like a button hence cursor should be a pointer
[class*='back-btn'], button {
	cursor: pointer;
}
