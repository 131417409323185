
		  @import "src/styles-theme/variables.scss";
		  
.external-check-header {
	display: flex;
	flex-direction: column;
	background-color: $color-primary-lighter;
	font-size: 16px;
	padding: 20px;
	border-radius: 0.25rem;
	color: #4a4a4a;

	// Count
	.count {
		font-size: 1.9375rem;
		font-weight: 500;
		color: $color-grey-1;
		margin-right: 0.625rem;
	}

	// Progress
	.external-applicant-progress {
		display: flex;
		height: 10px;
		width: 100%;
		border-radius: 0.5rem;
		margin-top: 0.5rem;
		overflow: hidden;

		span {
			display: block;
			height: 100%;
			border-radius: 5px;
			margin-right: 3px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.found-applicants {
		color: $color-grey-1;
		font-size: 16px;
		font-weight: 400;
		margin-top: 15px;
	}

	// Button
	.external-check-button {
		background-color: $primary-color;
		font-family: inherit;
		font-size: 100%;
		font-weight: 500;
		color: #fff;
		border: none;
		height: 40px;
		margin-bottom: 20px !important;
		width: 215px;
		border-radius: 0.375rem;
		padding: 0 2rem;
		cursor: pointer;

		&:active {
			background-color: #3d4ece;
		}
	}

	@media only screen and (min-width: 650px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.external-applicant-progress {
			width: 400px;
		}

		.external-check-button {
			margin: 0;
		}
	}
}
