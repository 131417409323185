
		  @import "src/styles-theme/variables.scss";
		  
@import '../../styles-theme/variables.scss';

.external-user-profile-container {
	flex: 1;
	padding: 1rem 0 1rem 1rem;

	.external-user-profile-header-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 15px;
		padding-bottom: 25px;

		.external-user-profile-header {
			font-weight: 500;
			font-size: 16px;
		}

		.external-user-profile-statuses {
			display: flex;
			flex-direction: row;
		}
	}
}