
		  @import "src/styles-theme/variables.scss";
		  


.profile-primary-container {
    height: 82vh;
    display: flex;
    flex-direction: column;
    margin-bottom: $size-24;
    @media only screen and (max-width:700px) {
        height: unset;
    }
    .primary-container-main {
        overflow-y: scroll;
        background-color: $color-white;
        flex-grow: 1;
        padding-bottom: $size-40;
        border-radius: 4px;
        // box-shadow: $shadow_1;
        padding-top: $size-24;

        @media only screen and (max-width: 700px) {
            padding-top: 0;
            margin-top: -$size-8;
        }



        &::-webkit-scrollbar {
            width: 0px;
        }

        .application-cancelled {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: $size-16 $size-32;
            background-color: $color-grey-8;
            padding: $size-8 $size-16;
            border-radius: 5px;
            .application-cancelled-left {
                display: flex;
                img {
                    margin-right: $size-16;
                }
                p {
                    font-size: 12px;
                    font-weight: $font_semi_bold;
                }
            }
            .application-cancelled-right {

                img {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                }

            }
        }

        .sia-section {
            background-color: $color-grey-8;

        }
    }
}

// Licence Check Date Container
.licence-checked-container {
	font-size: 14px;
	padding: 0 8px;
	margin: 4px 0;

	.content {
		height: 40px;
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 0 32px;
		background-color: #F7F7F7;
		border-radius: 5px;

		.licence-checked-date {
			font-weight: 600;
		}

		.faded-text {
			opacity: 0.5;
		}
	}

	@media screen and (min-width: 701px) {
		padding: 0 32px 0 104px;
	}
}
