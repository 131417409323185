
		  @import "src/styles-theme/variables.scss";
		  


.activity-address-section {
    display: flex;
    flex-wrap: wrap;
    margin: $size-24 0;
    @media only screen and (max-width: 700px) {
       
        margin: $size-24;
    }
}