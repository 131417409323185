
		  @import "src/styles-theme/variables.scss";
		  


.original-activity-item {
    background-color: $color-grey-8;
    padding: $size-24;
    margin-bottom: $size-16;
    p {
        font-size: 14px;
        line-height: $size-32;
    }
    .semi-bold {
        font-weight: $font_semi_bold;
    }
    .original-activity-item-top {
        display: flex;
        justify-content: space-between;
    }
    .original-activity-item-top-right {
        display: flex;
     
    }
}