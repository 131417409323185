
		  @import "src/styles-theme/variables.scss";
		  
.organisations-header {
    grid-area: organisations-header;
}
.organisations-sub-header {
    grid-area: organisations-sub-header;
}
.organisations {
    grid-area: organisations;
    margin-bottom: -$size-24;
}
.pagination-component {
    grid-area: pagination-component;
}

.organisations-main-container {
    flex-grow: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background-color: white;

    @media only screen and (max-width: 720px) {
        height: calc(95vh - 80px);
        margin: 4px;
    }
    display: grid;
    grid-template-columns: 100%;
    transition: 0.3s linear;
    grid-gap: $size-8;

    grid-template-areas:
    "organisations-header"
    "organisations-sub-header"
    "organisations"
    "organisation-pagination";
    grid-template-rows: $size-120 $size-80 auto $size-64;



    .organisations, .organisations-sub-header {
        &::-webkit-scrollbar {
            width: 0px;
        }
        overflow: scroll;
    }


	.organisations-row-container {
		border-bottom: 2px solid #f2f2f2;
	}

	.organisations-sub-header-container {
		border-bottom: 6px solid #f2f2f2;
	}
}