
		  @import "src/styles-theme/variables.scss";
		  
.edit-input-area {
	width: 100%;
	position: relative;
	.validation-error {
		font-size: 10px;
		color: $color-red-font;
		margin-top: -4px;
		font-weight: $font_semi_bold;
		position: absolute;
	}

	.edit-input-display {
		display: flex;
		margin: 4px 0;

		&.spaced {
			justify-content: space-between;
		}
		
		&.center {
			justify-content: center;
		}

		.edit-input {
			width: 100%;
			padding: 4px 2px 8px 2px;
			border-bottom: 1px solid $primary-color;
			margin-bottom: $size-8;
			font-weight: $font_normal;
			input {
				outline: none;
				border: none;
				width: 100%;
				color: $color-grey-1;
				font-size: 14px;
				font-weight: $font_semi_bold;
				&::placeholder {
					color: $color-grey-4;
					font-size: 14px;
					font-weight: normal;
				}
			}
		}

		.edit-input-date-picker fieldset.MuiOutlinedInput-notchedOutline {
			border-color: $primary-color;
		}
	}
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
	background-color: $primary-color !important;
}

.MuiButton-textPrimary {
	color: $primary-color !important;
}

// .MuiTypography-h4 {
//     font-family: $font_family!important;
//     font-weight: $font_demi_bold!important;
// }
.MuiInput-formControl {
	border-bottom: 1px solid $primary-color;
}

.MuiInput-underline:after {
	transition: unset !important;
	transform: unset !important;
	border-bottom: unset !important;
}

.MuiFormLabel-root {
	// font-weight: $font_demi_bold!important;
	font-size: 12px !important;
	color: $color_grey_3 !important;
	text-transform: capitalize !important;
	line-height: $size-16 !important;
	font-family: unset !important;
	&label {
		font-size: 12px !important;
		margin-bottom: $size-8 !important;
	}
}

.MuiInputLabel-shrink {
	transform: unset !important;
	transform-origin: unset !important;
}

.MuiInputBase-input {
	color: $color-grey-4;
	font-weight: 400 !important;
	font-size: 12px !important;
	font-family: $font_family;
}

.MuiInputBase-input::placeholder {
	font-weight: 400 !important;
	font-size: 12px !important;
	font-family: $font_family;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}

.MuiInput-underline:before {
	content: '' !important;
}
.MuiInput-underline:before {
	border-bottom: none !important;
}

.MuiInputLabel-formControl {
	top: -6px;
}
