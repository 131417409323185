
		  @import "src/styles-theme/variables.scss";
		  
.file-owner-container {
	border-bottom: 1px solid $color-grey-8;
	margin-bottom: $size-16;
	padding-bottom: 20px;

	.file-owner-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $size-32;
		img {
			cursor: pointer;
		}
	}

	.file-owner-items {
		align-items: center;
		background-color: #f7f7f7;
		border-radius: 5px;
		margin-right: 8px;
		margin-top: 8px;
		min-height: 24px;
		padding: 4px 16px;
		position: relative;
		width: fit-content;
		display: flex;
		img{
			width: 11px;
			margin-left:$size-8;
			cursor: pointer;
		}
		&::before {
			content: '';
			background-color: #485beb;
			border-radius: 5px 0 0 5px;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 2px;
		}

		p {
			font-size: 12px;
		}
	}
}
