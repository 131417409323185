
		  @import "src/styles-theme/variables.scss";
		  


.address-history-container {
    background-color: $color-grey-8;
    padding: $size-24;
    margin: 4px 0 4px $size-72;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
    
}