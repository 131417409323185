
		  @import "src/styles-theme/variables.scss";
		  


.add-staff-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    padding: $size-16 $size-32;
    margin-bottom: 4px;
    min-width: 600px;
    .add-staff-row-left {
        display: flex;
        align-items: center;
        .add-staff-info {
            width: 200px;
        }
        .email {
            margin-left: $size-64;
            p {
                color: $color-grey-3;
                font-size: 14px;
            }
        }

    }
    
    
}
