
		  @import "src/styles-theme/variables.scss";
		  
.profile-file-bucket-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 0px;
	// .profile-view {
	// 	margin-left: 72.3px;
	// }
	.file-upload-spacer {
		width: $size-64;
		max-width: $size-64;
		margin-right: $size-8;
		@media only screen and (max-width: 700px) {
			display: none;
		}
	}
	.profile-file-bucket-upload-area {
		display: flex;
		justify-content: center;
		width: 85%;
		border: solid 2px $color-primary-lighter;
		background-color: $color-primary-lighter;
		border-radius: 10px;
		height: 50px;

		.file-bucket {
			height: $size-48;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&.active {
				background-color: $primary-color;
				.files-empty {
					p {
						color: $color-white;
					}
				}
			}
		}

		.files-empty {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				p {
					font-size: 12px;
					color: $color-grey-1;
					.underline {
						position: relative;
						top: 0.5px;
						font-weight: $font_semi_bold;
						text-decoration: underline;
						text-underline-position: under;
					}
				}
		}
	}
	.profile-file-bucket-upload-area.disabled {
		background-color: $color-grey-8;
		border-color: $color-grey-8;
		pointer-events: none;
		cursor: not-allowed;
	}
}

