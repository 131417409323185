
		  @import "src/styles-theme/variables.scss";
		  


.manually-approve {
    display: flex;
    align-items: center;
    background-color: $color-grey-8;
    padding: $size-24;
    margin-left: $size-72-5;
    margin-bottom: 4px;
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
    
    
    button {
        margin-right: $size-16;
        font-size: 12px;
        cursor: pointer;
    }
    .btn-passed {
        color: $color-green-font;
        background-color: $color-green;
    }
    .btn-failed {
        color: $color-red-font;
        background-color: $color-red;
    } 
    .btn-pending {
        color: $color-yellow-font;
        background-color: $color_yellow;
    }
    p {
        font-size: 12px;
    }
}