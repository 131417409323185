
		  @import "src/styles-theme/variables.scss";
		  


.staff-rows {
    grid-area: staff-rows;
}
.add-staff-header {
    grid-area: settings-header;
}
.default-flow-container {
    grid-area: default-flow-container;
}

.settings-container {
    // margin: $size-16;
    flex-grow: 1;
    height: 95vh;
    
    @media only screen and (max-width: 720px) {
        height: calc(95vh - 80px);
        margin: 4px;
    }
    display: grid;
    grid-template-columns: 100%;
    transition: 0.3s linear;
    
    grid-template-areas:
    "settings-header"
    "staff-rows"
    "default-flow-container";
    grid-template-rows: $size-120 auto 140px;

	.staff-rows {
		overflow-y: scroll;
		margin-bottom: $size-8;
		.staff-added {
			padding: $size-16 $size-32;
			background-color: $color-white;
			margin-bottom: 4px;
			min-width: 600px;
			p {
				font-size: 14px;
				font-weight: $font_semi_bold;
				color: $color-grey-3;
			}
		}
	}

}


