
		  @import "src/styles-theme/variables.scss";
		  


// dashboard grid 

.dashboard-header {
    grid-area: dashboard-header;
}
.dashboard-sub-menu {
    grid-area: dashboard-sub-menu;
}
.dashboard-main-content {
    grid-area: dashboard-main-content;
}
.pagination-component {
    grid-area: pagination-component;
}


.dashboard-container {
    flex-grow: 1;
    height: 95vh;
    @media only screen and (max-width: 720px) {
        height: calc(95vh - 80px);
    }
    display: grid;
    grid-template-columns: 100%;
    transition: 0.3s linear;
    grid-gap: $size-8;
    grid-template-areas:
    "dashboard-header"
    "dashboard-main-content"
    "pagination-component";
    grid-template-rows: $size-96 auto $size-64;
    @media only screen and (max-width: 720px) {
        margin-left: 0;
    }

    .dashboard-main-content {
        overflow: scroll;
        //&::-webkit-scrollbar {
        //    width: 0px;
        //}

    }
}

