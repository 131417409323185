
		  @import "src/styles-theme/variables.scss";
		  


.activity-email-modal {
  p {
    font-size: 14px;
  }
    .email-modal-step-one {
        margin-bottom: $size-16;
        display: flex;
        align-items: center;
        img {
            margin-left: $size-8;
            width: $size-16;
            height: $size-16;
            cursor: pointer;
        }
    }
  .email-modal-step-two {
      input[type='file'] {
          display: none;
      }
      img {
          width: 18px;
          height: 18px;
          cursor: pointer;
          margin-top: 4px;
      }
    .attached {
        color: $color-grey-3;
        font-style: italic;
    }
    .email-modal-step-two-upload {
        display: flex;
        align-items: center;
    }
      .custom-file-upload {
          display: inline-block;
          padding: $size-8;
          cursor: pointer;
      }
  }
    .modal-button {
        bottom: $size-32;
        right: $size-32;
    }
}
