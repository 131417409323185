
		  @import "src/styles-theme/variables.scss";
		  


.profile-header {
  background-color: $color-grey-8;
  display: flex;
  align-items: center;
  min-height: 92px;
  height: 92px;
  .profile-header-screener {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 $size-24;
    .profile-header-info-section {
      display: flex;
      align-items: center;
	  .profile-name {
		display: flex;
		justify-content: flex-start;
		flex-grow: 2;
		margin-right: 15px;
	  }
      .back-btn {
        img {
          margin-right: $size-8;
          margin-top: 4px;
        }
      }
      .profile-header-info-actions {
        display: flex;
        align-items: center;
        margin-right: $size-16;
        .applicant-notes {
          margin-left: 4px;
        }
      }
    }
	.profile-header-assigned-section {
		display: flex;
		margin-top: 10px;
		.profile-last-chased {
			margin-right: $size-32;
			transform: translateY(-16px);
		}
	}
  }
  @media only screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      height: 120px;
      margin-bottom: $size-16;
      background-color: $color-white;
      padding: 0 $size-16;
    .profile-header-screener {
      border-radius: 5px;
      align-items: flex-start;
      .profile-header-info-section {
        width: 100%;
        padding: $size-16;

        flex-direction: column-reverse;
        align-items: flex-start;

      }
      .profile-header-assigned-section {
        margin-top: 12px;
      }
    }
  }

  .profile-header-content-ambassadors {
      display: flex;
      width: 100%;
      align-items: center;
    .back-btn {
      margin-right: $size-8;
      margin-top: 4px;
    }
  }
}
