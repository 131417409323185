
		  @import "src/styles-theme/variables.scss";
		  


.layout-container {
    flex-grow: 1;
    transition: 0.3s linear;
    overflow: visible;
    height: 95vh;

    @media only screen and (max-width: 720px) {
        margin-left: 4px!important;
        margin: 4px;
        height: calc(95vh - 64px)
    }
}