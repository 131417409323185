
		  @import "src/styles-theme/variables.scss";
		  


.id-check-doc-info {
    background-color: $color-grey-8;
    padding: $size-24 $size-32;
    .document-info {
        display: flex;
        flex-wrap: wrap;
        .document-info-item {
            margin: $size-16 $size-64 $size-16 0;
        }
       
    }
    .id-image-section {

        .id-image-section-images {
            display: flex;
            
            margin-bottom: $size-16;
            a {
                margin-right: $size-24;
                margin-bottom: $size-8;
                margin-top: 4px;
                font-size: 14px;
            }
            
        }
    }

    .onfido {
        a {
            margin-top: 4px;
            font-size: 14px;
        }
    }
}