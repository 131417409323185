
		  @import "src/styles-theme/variables.scss";
		  
.certificate-container {
	width: 90%;
	padding: 10px;
}
.certificate-header-new {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 10px;
}
.certificate-header-right {
	display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.certificate-title-text {
	font-size: $size-14;
	margin-top: 5px;
}
.certificate-header-text {
	font-size: $size-8;
	color: $color-grey-1;
	font-weight: 400;
	position: relative;
	z-index: 10000000;
	text-align: center;
}
.screening-icon {
	height: 15px;
	width: auto;
}
.deploi-img {
	height: 28px;
	width: auto;
	margin-top: 5px;
	transform: translateX(-8px) translateY(10px);
}