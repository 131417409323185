
		  @import "src/styles-theme/variables.scss";
		  


.pagination-component {
    background-color: $color-white;
    display: flex;
    justify-content: center;
    width: 100%;
    height: $size-56;
    align-items: center;
    margin-top: $size-8;
	margin-bottom: $size-8;
	bottom: 2px;
}

.MuiPaginationItem-outlined {
    border: none!important;
}

.MuiPaginationItem-rounded {
    border-radius: 50%!important;

}

.MuiPaginationItem-page.Mui-selected {
    background-color: $color-white!important;
    border: 1px solid $primary-color!important
}