
		  @import "src/styles-theme/variables.scss";
		  


.conviction-section {
	background-color: $color-grey-8;
	margin-left: $size-72-5;
	padding: $size-24;
	margin-top: 4px;
	@media only screen and (max-width: 700px) {
		margin-left: unset;
	}
	.conviction-item {
		margin: $size-16 0;
	}
}
