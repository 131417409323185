
		  @import "src/styles-theme/variables.scss";
		  


.add-staff-header {
	margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    padding: $size-32;
    margin-bottom: 4px;
    .flex-buttons {
        display: flex;
        align-items: center;
        button:first-of-type{
            margin-right: $size-16;
        }
    }
    @media only screen and (max-width:700px)  {
        .desktop-btn {
            display: none;
        }
        .mobile-btn {
            img {
                width: $size-32;
                height: $size-32;
                cursor: pointer;
                margin-left: $size-16;
            }
        }
    }

    @media only screen and (min-width:700px)  {
        .mobile-btn {
            display: none;
        }
    }
    
}
