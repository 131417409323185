
		  @import "src/styles-theme/variables.scss";
		  



.profile-display-item {
    margin-bottom: $size-16;
    .clickable {
        cursor: pointer;
        color: $primary-color;
        text-decoration: underline;
        text-underline-position: under;
    }
}
