
		  @import "src/styles-theme/variables.scss";
		  


.activity-item-container {
    margin-right: $size-32;
    min-width: 200px;
    width: calc(50% - 40px);

    .orka-profile-label {
        margin-bottom: 4px;
    }
 
    .activity-item-value {
        display: flex;
        align-items: center;
        .pointer {
            cursor: pointer;
            margin-left: $size-16;
            margin-bottom: 12px;
        }
        .icon {
            margin-left: $size-16;
            margin-bottom: 12px;
        }
        
    }

    .dropdown-top-content {
        margin-top: 8px
    }
}