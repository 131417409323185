
		  @import "src/styles-theme/variables.scss";
		  


.show-assigned-container {

  height: calc(100% - 8px);
  .show-assigned {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    width: 100%;
    .show-assigned-text {
        margin-bottom: 8px;
        p {
            font-size: 12px;
            color: $color-grey-3;
            transform: translateY(6px);
            font-weight: $font_semi_bold;
            margin-left: 2px;
        }
    }
  }
}
