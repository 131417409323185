
		  @import "src/styles-theme/variables.scss";
		  


.stage-notes {
    padding: $size-24;
    background-color: $color-grey-8;
    margin-left: $size-72-5;
    margin-bottom: 4px;
    @media only screen and (max-width: 700px) {
        margin-left: unset;
        padding: $size-16;
    }
    .stage-notes-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $size-16;
        button {
            box-sizing: border-box;
            height: 40px;
        }
    }

    textarea {
        width: 96%;
        height: 120px;
        border: none;
        outline: none;
        border-radius: 4px;
        font-size: 12px;
        padding: $size-8;
        color: $color-grey-1;
        line-height: 24px;
        @media only screen and (max-width: 700px) {
            width: 95%;
        }
    }
  


}
