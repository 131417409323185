
		  @import "src/styles-theme/variables.scss";
		  



.stage-complete-container {
    width: $size-64;
    min-width: $size-64;

    height: $size-48;
    background-color: $color-grey-8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    @media only screen and (max-width: 700px) {
        width: $size-40;
        min-width: $size-40;
        border-radius: 5px 0 0 5px;
    }
    
    .stage-complete-inner {
        border: 1px solid $color-grey-4;
        width: $size-24;
        height: $size-24;
        border-radius: 50%;
        

        
        
    }
  
    &.stage-complete {
        background-color: $primary-color;
        .stage-complete-inner {
            border-color: $color-white;
            background-color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
            }
        
        }
    }
        &.not-clickable {
            background-color: $color-white;
            cursor: unset;
              @media only screen and (max-width: 700px) {
                display: none;
            }
    }
}