
		  @import "src/styles-theme/variables.scss";
		  


.login-input {
    display: flex;
    align-items: center;
    background-color: $color-white;
    width: 373px;
    height: $size-48;
    margin-bottom: $size-24;

    @media only screen and (max-width: 600px) {
        width: 300px;
    }
    @media only screen and (max-width: 340px) {
        width: 280px;
    }

    img {
        margin: 0 $size-16;
    }


    .login-stripped-input {
        border: none;
        outline: none;
        font-size: $size-16;
        font-weight: $font_normal;
        color: $color-grey-1;
        width: 100%;

    }

    &.password-validation-error {
        border: 1px solid $color-red-font;
    }

    &.phone-validation-error {
        border: 1px solid $color-red-font;
    }

    .show-password-button {
        background: transparent;
        border: none;
        cursor: pointer;
    }

  
}
