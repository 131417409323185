
		  @import "src/styles-theme/variables.scss";
		  


.assigned-dropdown-container {
    display: block;
    position: absolute;
    clear: both;

    background-color: white;
    z-index: 10000;
    box-shadow: $shadow_5;
    border-radius: $size-8;
    padding: $size-16 0;
    transform: translateY(40px);
    width: 225px;
    max-height: 200px;
    overflow-y: scroll;
    &.dropdown-aligned-right {
        right: 0;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
    .assigned-dropdown-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px $size-16;
        height: $size-40;
        cursor: pointer;
        p {
            font-size: 14px;
            font-weight: $font_semi_bold;
        }
    }
    .color-green {
        color: #1DC7B2;
    }
    .color-blue {
        color: #485BEB;
    }
    .color-orange {
        color: #FF7B00;
    }
    .color-purple {
        color: #9013FE;
    }
    .color-red {
        color: #EC8079;
    }

}




