
		  @import "src/styles-theme/variables.scss";
		  

   
.profile-tags {
    border-bottom: 1px solid $color-grey-8;
    padding-top: $size-8;
    padding-bottom: $size-16;
    margin-bottom: $size-24;
}

.profile-tags-is-ambassador {
    border-bottom: none;
    padding: $size-8 0 $size-16 0;
    margin-bottom: 0;
}

.tags-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $size-32;
    img {
        cursor: pointer;
    }
}

.tag-search-outer {
    position: relative;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    .tag-container {
        background-color: $color-grey-8;
        border-radius: 5px;
        padding: 4px $size-16;
        cursor: pointer; 
        min-height: $size-24;
        margin-right: $size-8;
        margin-top: $size-8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: $color-black;
            font-size: 10px;
            cursor: help;
            margin-right: $size-8;
        }
        img {
            width: 10px;
            height: 10px;
        }
        &.urgent {
            background-color: $primary-color;
            p {
                color: $color-white;
            }
        }
    }
}

  
