
		  @import "src/styles-theme/variables.scss";
		  
.file-list-container {
	.file-draggable {
		cursor: move;
		margin-bottom: $size-16 !important;
		padding-bottom: $size-8;
		padding-top: $size-8;
		border-bottom: 1px solid $color-grey-6;
	}
	.file-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $size-48;
		margin: 0px;

		&.no-padding {
			height: $size-32;
		}

		.file-item-left {
			display: flex;
			flex-grow: 1;
			.checkbox-area {
				width: $size-64;
				min-width: 64px;
				margin-right: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				@media only screen and (max-width: 700px) {
					display: none;
				}
				img {
					cursor: pointer;
				}
			}
			.file-link {
				background-color: $color-grey-8;
				height: $size-48;
				display: flex;
				align-items: center;
				flex-grow: 1;
				overflow: none;
				&.no-padding {
					padding-left: 0;
				}
				.preview-image {
					width: 40px;
					height: 40px;
				}

				a {
					text-transform: lowercase;
					// white-space: nowrap;
					font-size: 14px;
					margin-left: $size-24;
				}
			}
			img {
				-webkit-user-drag: none;
				-khtml-user-drag: none;
				-moz-user-drag: none;
				-o-user-drag: none;
				user-drag: none;
			}
		}
		.file-item-right {
			padding-right: $size-24;
			background-color: $color-grey-8;
			height: $size-48;
			display: flex;
			align-items: center;
			img {
				cursor: pointer;
				width: 12px;
			}
		}
	}
}
