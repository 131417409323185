
		  @import "src/styles-theme/variables.scss";
		  


.rtw-section-container {
    
    .ni-number {
        margin: 4px 0 4px $size-72;
        padding: 0 $size-32;
        background-color: $color-grey-8;
        height: $size-48;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 700px) {
            margin-left: 0;
        }
        p {
            font-size: 14px;
            font-weight: $font_semi_bold;
        }
    }
}