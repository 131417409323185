
		  @import "src/styles-theme/variables.scss";
		  


.upload-to-onfido {
    padding: 12px;
    background-color: $color_grey_8;
    margin: 4px 0;
    margin-left: $size-72-5;
    display: flex;
    justify-content: center;
    cursor: pointer;
    p {
        font-size: 12px;
        .underline {
            position: relative;
            top: 0.5px;
            font-weight: $font_semi_bold;
            text-decoration: underline;
            text-underline-position: under;
        }
    }
    @media only screen and (max-width: 700px) {
        margin-left: 0;
    }
}
.rtw-manual-container{
	margin-left: $size-72-5;
	background-color: $color-grey-8;
	padding: $size-32;
	@media only screen and (max-width: 700px) {
        margin-left: 0;
    }

	.confidence-level {
		font-weight: $font_normal;
		display: flex;
		align-items: center;
		gap: $size-8;
	}

}