
		  @import "src/styles-theme/variables.scss";
		  
.dashboard-sub-menu {
	display: flex;
	align-items: center;
	height: $size-96;
	background-color: $color-white;
	padding: 0 $size-16;
	border-bottom: 4px solid $color-grey-8;
	justify-content: flex-start;

	.dashboard-sub-menu-row {
		display: grid;
		grid-template-columns: repeat(7, 150px);
		align-items: flex-end;

		.clear-btn {
			display: flex;

			button {
				align-self: flex-end;
				height: 34px;
				background-color: $color-white;
				outline: none;
				border: 1px solid $primary-color;
				border-radius: 6px;
				margin-bottom: 1px;
				color: $primary-color;
				padding: 0 $size-8;
				cursor: pointer;
			}
		}
	}
}

@media (max-width: 1440px) {
	.dashboard-sub-menu {
		height: 130px;
	}

	.dashboard-sub-menu-row {
		grid-template-columns: repeat(6, 150px) !important;
	}

	.clear-btn {
		margin-top: 10px;
	}
}

@media (max-width: 1300px) {
	.dashboard-sub-menu {
		height: 155px;
	}

	.dashboard-sub-menu-row {
		grid-template-columns: repeat(5, 150px) !important;
	}
}
