
		  @import "src/styles-theme/variables.scss";
		  
.reset-rtw-section {
	margin-left: $size-72-5;
	// padding: $size-24;
	background-color: $color-grey-8;
	@media only screen and (max-width: 700px) {
		margin-left: unset;
	}

	.reset-rtw {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		button {
			box-sizing: border-box;
			height: 40px;
		}
	}
}
