
		  @import "src/styles-theme/variables.scss";
		  


.organisation-info-container {
    background-color: $color-grey-8;
    margin-bottom: $size-32;
    padding: $size-24;
    p {
        font-size: 14px;
        line-height: 28px;
    }
    .organisation-info-container-name {
        .name {
            font-weight: $font_demi_bold;
        }
    }
}