
		  @import "src/styles-theme/variables.scss";
		  


.profile-section {
   
    background-color: $color-white;
    margin: 0 $size-32 $size-32 $size-32;
    @media only screen and (max-width: 700px) {
        margin: 0 $size-8;
    }
    
     
    .profile-section-title {
     
      
        height: $size-32;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .profile-section-title-space {
            width: $size-64;
            height: $size-32;
            background-color: $color-white;
            margin-right: $size-8;
           
            @media only screen and (max-width: 700px) {
                display: none;
            }
            
        } 
        .profile-section-title-text {
			justify-content: space-between;
            background-color: $color-grey-5;
            height: 40px;  
            display: flex;
            align-items: center;
            flex: 1;
			img{
				margin-right: 15px;
				width: 12px !important;
				height: 12px;
				cursor: pointer;
				padding:12px;
			}
            h4 {
                font-size: 12px;
                font-weight: $font_demi_bold;
                padding-left: $size-32;
            }

        }
        
         
        
    }
}