
		  @import "src/styles-theme/variables.scss";
		  
.modal-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	background-color: $color-grey-1;
	overflow: hidden;
	transition: 3s ease-in-out;
	.modal-content {
		width: 80%;
		max-height: 80vh;
		overflow-y: auto;
		background-color: $color-white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: $size-32;
		border-radius: 4px;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 50px;
			background: $color-grey-2;
		}
		.modal-header {
			height: $size-64;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			@media only screen and (max-width: 700px) {
				margin-bottom: $size-40;
			}
			.modal-header-title {
				display: flex;
				img {
					width: $size-24;
					height: $size-24;
					margin-right: $size-8;
					margin-top: 6px;
				}
				h1 {
					margin-left: 0;
				}
			}
			img {
				cursor: pointer;
			}
		}
	}
}
