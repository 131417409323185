
		  @import "src/styles-theme/variables.scss";
		  


.alert-confirm {
    width: $size-560;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
    position: fixed;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    padding: $size-40;
    border-radius: 10px;
    background-color: $color-grey-6;
    box-shadow: $shadow_3;
    z-index: 10000000000;
   
   
    .alert-confirm-message {
        margin-bottom: $size-24;
        h2 {
            margin-bottom: $size-16;
        }
        p {
            font-size: 14px;
            margin-bottom: $size-16;
        }

    }
    .alert-confirm-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            margin: 0 $size-16;
            font-weight: $font_semi_bold;
            font-size: 14px;
            padding: 0;
           
            &.confirm {
                background-color: $color-green-font;
                color: $color-white;
            }
            &.reject {
                background-color: $color-red-font;
                color: $color-white;
            }
        }

    }
}