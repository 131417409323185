
		  @import "src/styles-theme/variables.scss";
		  
.passport-card-wrapper {
	width: 350px;
	margin: 0 auto;
	overflow: hidden;
	.passport-card {
		padding: $size-24;
		background: #e6e6f8;
		background-repeat: no-repeat;
		background-position: 12px 23px;
		height: 134px;
		border-radius: 24px;
	}
	.passport-title {
		line-height: 1;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		img {
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.text-right {
		text-align: right;
	}
	.passport-wrapper-details {
		display: flex;
	}
	.orka-card-label {
		font-size: 8px;
		color: $color-grey-3;
		line-height: 2;
		margin-top: 8px;
	}
	.orka-card-text {
		font-size: 10px;
		color: $color-grey-1;
		line-height: 1;
	}
	.left-column {
		flex: 0.6;
	}
}
.passport-half {
	height: 108px;
	.passport-card {
		border: dashed $primary-color 1px;
	}
}
.passport-full {
	height: 189px;
	.passport-card {
		border: solid $primary-color 1px;
	}
}
