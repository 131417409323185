
		  @import "src/styles-theme/variables.scss";
		  


.number-input {
    p {
        margin-bottom: $size-8;
    }
    input[type=number] {
        line-height: 40px;
        outline: none;
        border: none;
        background-color: #F1F1F1;
        width: $size-40;
        border-radius: 5px;
        padding: 0 4px 0 $size-16 ;
        font-size: 14px;
        font-weight: $font_semi_bold;
        color: $color-grey-3;
    }
      
    input[type=number]::-webkit-inner-spin-button {
        width: 16px;
        height: 40px;
        background-color: #F1F1F1;

    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
        opacity: 1;
    }
}
