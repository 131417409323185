
		  @import "src/styles-theme/variables.scss";
		  
.staff-action-modal-content {
	background-color: $color-white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	
	.company-selection {
		display: flex;
		margin-left: 15px;
	}
	
	.settings-small {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 15px;
	}
	
	.form-content {
		padding: 0 30px;
	}

	.form-group {
		display: flex;
		gap: 20px;
		margin-bottom: 20px;
	}

	.form-header,
	.form-footer {
		padding: 25px;
		background-color: $color-white;
	}

	.form-footer {
		display: flex;
		justify-content: flex-end;
		gap: 15px;

		.form-button {
			background-color: $color-white;
			font-family: inherit;
			font-size: 100%;
			font-weight: 500;
			color: $color-grey-1;
			border: 1px solid $color-grey-2;
			height: 40px;
			border-radius: 0.25rem;
			padding: 0 2rem;
			min-width: 150px;
	
			&.primary {
				background-color: $primary-color;
				border: 1px solid $primary-color;
				color: $color-white;
			}
	
			&:active {
				opacity: 0.8;
			}

			&:disabled {
				background-color: $primary-color;
				color: $color-white;
				border: 1px solid $primary-color;
				cursor: not-allowed;
				opacity: 0.6;
			}
	}

	.form-body {
		height: 100%;
		overflow-y: auto;
	}
	}

	.text-input {
		flex: 1;

		input {
			font-size: 16px;
			font-weight: 500;
			width: 100%;
			height: 40px;
			outline: none;
			border: none;
		}

		::placeholder {
			color: $color-grey-2;
			opacity: 1;
			font-weight: 400;
		}
		:-ms-input-placeholder {
			color: $color-grey-2;
			font-weight: 400;
		}
		::-ms-input-placeholder {
			color: $color-grey-2;
			font-weight: 400;
		}
	}
}

.staff-action-modal-delete-content {
	width: $size-304;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 0;
	}
	position: fixed;
	top: 50%;  
	left: 50%; 
	transform: translate(-50%, -50%);
	padding: 35px;
	border-radius: 5px;
	background-color: $color-grey-6;
	box-shadow: $shadow_3;
	z-index: 9999;
	.staff-action-modal-delete-header {
		margin-bottom: 25px;
		img {
			float: right;
			cursor: pointer;			
		}
	}
	.staff-action-modal-delete-message {
		margin-bottom: $size-24;
		
		.staff-action-modal-delete-title {
			margin-bottom: 5px;
			font-weight: $font_normal;
			font-size: $size-16;
		}
		.staff-action-modal-delete-name {
			font-weight: $font_demi_bold;
			font-size: $size-16;
			padding-bottom: 5px;
		}
	}
	.staff-action-modal-delete-explainer {
		font-weight: $font-normal;
		font-size: $size-14;
		color: $color-grey-3;
	}

	.staff-action-modal-delete-button {
		display: flex;
		align-items: center;
		justify-content: right;
		
		.delete-button {
			background-color: $primary-color;
			font-family: inherit;
			font-size: 16px;
			font-weight: 500;
			color: $color-white;
			border: none;
			height: 40px;
			border-radius: 0.25rem;
			padding: 0 2rem;
			cursor: pointer;
	
			&:active {
				background-color: $primary-color;
			}
		}
	}
}