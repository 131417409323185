
		  @import "src/styles-theme/variables.scss";
		  
.login-layout {
    width: 100%;
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .layout-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .logo {
            margin-bottom: $size-40;
            img {
                width: 240px;
            }
        }
        
    
    }
}